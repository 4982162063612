import React from 'react'
import BaseModule from '../BaseModule'
import Select from 'react-select'
import { POST_DISTORTION_SETUP } from '../Dashboard4_Transfery'
import { selectStyles } from '../select-styles'

export default class ModuleDistortionSetup extends BaseModule {

  constructor (props) {
    super(props)
    this.state = {
      ...this.state,
      errorsInRows: [],
      changes: {}
    }
  }

  lastLoadedData = null

  getFieldClassName (crypto, field) {
    const { errorsInRows, changes } = this.state
    let className = ''
    if (errorsInRows.includes(crypto) && ['shortageAlarm', 'shortageAllowed', 'surplusAllowed', 'surplusAlarm'].includes(field)){
      className += ' field-error'
    }
    if (changes[crypto] && changes[crypto][field]) {
      className += ' field-changed'
    }
    return className
  }

  getFieldValue (crypto, field) {
    const { changes } = this.state
    if (changes[crypto] && changes[crypto][field] !== undefined) {
      return changes[crypto][field]
    } else {
      return this.lastLoadedData[crypto][field]
    }
  }

  onFieldChange (crypto, field, value) {
    console.log(value)
    const { changes } = this.state
    if (!changes[crypto]) {
      changes[crypto] = {}
    }
    changes[crypto][field] = value
    this.setState({ changes })
  }

  validateChanges() {
    const { changes } = this.state
    const errorsInRows = []
    Object.keys(changes).forEach(crypto => {
      const shortageAlarm = +this.getFieldValue(crypto, 'shortageAlarm')
      const shortageAllowed = +this.getFieldValue(crypto, 'shortageAllowed')
      const surplusAllowed = +this.getFieldValue(crypto, 'surplusAllowed')
      const surplusAlarm = +this.getFieldValue(crypto, 'surplusAlarm')

      if (!(shortageAlarm <= shortageAllowed && shortageAllowed <= surplusAllowed && surplusAllowed <= surplusAlarm)) {
        errorsInRows.push(crypto)
      }
    })
    this.setState({ errorsInRows })
    return errorsInRows.length === 0
  }

  saveChanges() {
    if (this.validateChanges()) {
      const update = {}
      Object.keys(this.lastLoadedData).forEach(crypto => {
        update[crypto] = {};
        ['shortageAlarm', 'shortageAllowed', 'surplusAllowed', 'surplusAlarm', 'timeTable'].forEach(field => {
          update[crypto][field] = this.getFieldValue(crypto, field)
        })
      })
      this.fetchData(POST_DISTORTION_SETUP, update)
        .then(data => {
          this.setState({ changes: {} })
          this.onDataLoad(data, true)
        })
    }
  }

  renderBody ({ data, formData }) {
    this.lastLoadedData = data

    const { changes, errorsInRows } = this.state
    const hasChanges = !!Object.keys(changes).length
    const hasErrors = !!Object.keys(errorsInRows).length

    const timeTableOpts = formData.timeTable.map(x => ({ value: x, label: x }))

    const rows = Object.keys(this.lastLoadedData).map(crypto => {
      const timeTable = this.getFieldValue(crypto, 'timeTable')
      const timeTableOpt = timeTableOpts.find(x => x.value === timeTable)

      return (
        <tr key={crypto}>
          <td>{crypto}</td>
          <td className={this.getFieldClassName(crypto, 'shortageAlarm')}>
            <input type="text"
                   value={this.getFieldValue(crypto, 'shortageAlarm')}
                   onChange={e => this.onFieldChange(crypto, 'shortageAlarm', e.target.value)}
            />
          </td>
          <td className={this.getFieldClassName(crypto, 'shortageAllowed')}>
            <input type="text"
                   value={this.getFieldValue(crypto, 'shortageAllowed')}
                   onChange={e => this.onFieldChange(crypto, 'shortageAllowed', e.target.value)}
            />
          </td>
          <td className={this.getFieldClassName(crypto, 'surplusAllowed')}>
            <input type="text"
                   value={this.getFieldValue(crypto, 'surplusAllowed')}
                   onChange={e => this.onFieldChange(crypto, 'surplusAllowed', e.target.value)}
            />
          </td>
          <td className={this.getFieldClassName(crypto, 'surplusAlarm')}>
            <input type="text"
                   value={this.getFieldValue(crypto, 'surplusAlarm')}
                   onChange={e => this.onFieldChange(crypto, 'surplusAlarm', e.target.value)}
            />
          </td>
          <td className={this.getFieldClassName(crypto, 'timeTable')}>
            <Select options={timeTableOpts}
                    value={timeTableOpt}
                    onChange={value => this.onFieldChange(crypto, 'timeTable', value.value)}
                    styles={selectStyles}
            />
          </td>
        </tr>
      )
    })

    const errorField = !hasErrors ? null : (
      <span>
        Please follow the rule: <br/>
        shortageAlarm ⩽ shortageAllowed ⩽ surplusAllowed ⩽ surplusAlarm
      </span>
    )

    return (
      <div className={'mod-distortion-setup'}>
        <table className={'table-stripped'}>
          <thead>
          <tr>
            <th>Crypto</th>
            <th>shortageAlarm</th>
            <th>shortageAllowed</th>
            <th>surplusAllowed</th>
            <th>surplusAlarm</th>
            <th>timeTable</th>
          </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <div className={'fields-footer'}>
          {errorField}
          <button type="button"
                  className={'btn'}
                  style={{float: 'right'}}
                  disabled={!hasChanges}
                  onClick={() => this.saveChanges()}
          >Save changes
          </button>
        </div>
      </div>
    )
  }
}
