import {
  ADD_TRANSACTION_SUCCESS,
  CASHIER_ACTION,
  CASHIER_ACTION_FAILURE,
  CASHIER_ACTION_SUCCESS,
  CASHIER_SWITCH_2FA_SUCCESS,
  GET_CASHIER_SUCCESS,
  GET_CASHIERS_LIST_FAILURE,
  GET_CASHIERS_LIST_SUCCESS,
  GET_TRANSACTIONS_LIST_FAILURE,
  GET_TRANSACTIONS_LIST_SUCCESS,
  SET_CASHIER_4_EDIT,
  SET_DRAWER,
  SET_TABLE_PAGINATION,
  SET_TABLE_SORT_PARAMS,
  UPDATE_CASHIER,
  UPDATE_CASHIER_FAILURE,
  UPDATE_CASHIER_SUCCESS
} from './cashiersActions'

export default (previousState = {
  cashiers: [],
  cashier4edit: null,
  transactions: [],
  tableTotalItems: 0,
  tablePagination: { page: 0, size: 10 },
  tablesSortParams: {
    cashiers: null, // { order: string, orderBy: string } | null
    transactions: null
  },
  isLoading: 0,
  drawerType: '',
  drawerOpen: false
}, { type, payload }) => {
  switch (type) {
    case GET_CASHIERS_LIST_SUCCESS: {
      return {
        ...previousState,
        cashiers: payload.data.cashiers,
        tableTotalItems: payload.data.totalItems,
        drawerType: 'cashiers',
        drawerOpen: true, // Open drawer on data load
        status: 1
      }
    }
    case GET_CASHIERS_LIST_FAILURE: {
      return { ...previousState, cashiers: [], status: 0 }
    }
    case GET_TRANSACTIONS_LIST_SUCCESS:
    case ADD_TRANSACTION_SUCCESS:
      const { txs, totalItems, franchisee } = payload.data

      // kludge, saving user data. Getting franchisee on GET_TRANSACTIONS_LIST_SUCCESS
      if (franchisee) {
        window.curUserIsFriend = franchisee.isFriend
      }

      return {
        ...previousState,
        transactions: txs,
        tableTotalItems: totalItems,
        drawerType: 'transactions',
        drawerOpen: true, // Open drawer on data load
        status: 1
      }

    case GET_TRANSACTIONS_LIST_FAILURE: {
      return { ...previousState, transactions: [], status: 0 }
    }
    case GET_CASHIER_SUCCESS: {
      return {
        ...previousState,
        cashier4edit: payload.data.cashier,
        status: 1
      }
    }
    case SET_TABLE_PAGINATION:
      return { ...previousState, tablePagination: payload }

    case SET_TABLE_SORT_PARAMS:
      const { tablesSortParams } = previousState
      return {
        ...previousState,
        tablesSortParams: {
          ...tablesSortParams,
          [payload.type]: payload.sortParams
        }
      }

    case CASHIER_ACTION:
    case CASHIER_ACTION_FAILURE:
      return { ...previousState, ...payload }

    // Update cashiers list on success change
    case CASHIER_ACTION_SUCCESS:
    case CASHIER_SWITCH_2FA_SUCCESS:
    case UPDATE_CASHIER_SUCCESS: {
      const cashiers = previousState.cashiers.map((item) => {
        return item.id === payload.data.cashier.id
          ? { ...item, ...payload.data.cashier }
          : item
      })
      return { ...previousState, cashiers }
    }

    case SET_DRAWER:
      return {
        ...previousState, drawerType: payload.type, drawerOpen: payload.state
      }

    case UPDATE_CASHIER:
    case UPDATE_CASHIER_FAILURE:
      return { ...previousState, ...payload }
    case SET_CASHIER_4_EDIT: {
      return {
        ...previousState,
        cashier4edit: payload.cashier
      }
    }
    default:
      return previousState
  }
};
