import React from 'react'
import BaseModule from '../BaseModule'
import { LAST_TX_ACTION } from '../Dashboard1'

const ACTIONS = [
  { action: 'push', key: 'pushable' },
  { action: 'recalc', key: 'recalcable' },
  { action: 'bump', key: 'bumpable' },
]

export default class ModuleLastTx extends BaseModule {

  renderBody ({ data, additionalData }) {
    const headersI18n = additionalData.headers
    const buttonsI18n = additionalData.buttons

    const tableHeader = Object.entries(headersI18n).map(x => (
      <th key={x[1]}>{x[1]}</th>
    ))

    const tableRows = data.map((row, i) => {
      const cells = Object.keys(headersI18n).map(key => {
        return (key !== 'paidAmount' && key !== 'address') ?
          (
            <td
              key={key}
              style={{ textTransform: ['crypto', 'fiat'].includes(key) ? 'uppercase' : 'none' }}
              dangerouslySetInnerHTML={{ __html: row[key] !== undefined ? row[key] : '--' }}
            />
          ) : (
            <td key={key}>{this.renderPopover(row, key)}</td>
          )
      })

      const buttons = ACTIONS
        .filter(({ key }) => row[key])
        .map(({ key, action }) => (
          <button
            key={action}
            type={'button'}
            className={'tx-action-btn'}
            onClick={() => this.action(action, row.id)}
            style={{ whiteSpace: 'nowrap' }}
          >
            {action === 'bump' ? row[key] : buttonsI18n[action]}
          </button>
        ))

      return (
        <tr
          key={i}
          style={{ background: row.background, color: row.color }}
        >
          {cells}
          <td>{buttons}</td>
        </tr>
      )
    })

    return (
      <div className={'mod-last-tx'}>
        <table>
          <thead>
          <tr>
            {tableHeader}
            <th></th>
          </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    )
  }

  renderPopover (row, key) {
    let popoverContent = null

    if (key === 'paidAmount' && row.diffPayment) {
      popoverContent = (
        <div className={'d-popover-content d-popover-content_grey'}>{row.diffPayment} {row.fiat}</div>
      )
    } else if (key === 'address' && row.qrcode) {
      popoverContent = (
        <div className={'d-popover-content d-popover-content_grey'}>
          <div className={'qrcode'} style={{ backgroundImage: `url(${row.qrcode})` }}/>
        </div>
      )
    }

    return (
      <div className={'d-popover-wrapper'}>
        <span dangerouslySetInnerHTML={{ __html: row[key] !== undefined ? row[key] : '--' }}/>
        {popoverContent}
      </div>
    )
  }

  action (action, id) {
    const data = { [action]: id }
    this.fetchData(LAST_TX_ACTION, data)
      .then(data => {
        this.onDataLoad(data, true)
      })
  }

}
