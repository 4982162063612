import React from 'react'
import BaseModule from '../BaseModule'

export default class ModuleProfitAgregated extends BaseModule {

  renderBody (allData) {
    const { data, dataSeparated } = allData

    const dataRowFields = Object.keys(Object.entries(data)[0][1])
    const rows = Object.entries(data).map(([year, yearItem], i) => {
      return (
        <tr key={i}>
          <td><span dangerouslySetInnerHTML={{ __html: year }}/></td>
          {dataRowFields.map(field => {
            return (
              <td key={field} style={{ textAlign: 'right', paddingLeft: '20px' }}>
                {this.renderCell(
                  yearItem[field],
                  field === dataRowFields.at(-1),
                  dataSeparated[field] ? dataSeparated[field][year] : null,
                )}
              </td>
            )
          })}
        </tr>
      )
    })

    return (
      <table className="table table-stripped">
        <thead>
        <tr style={{ background: 'white' }}>
          <th>&nbsp;</th>
          {dataRowFields.map(field => <th key={field} style={{ textAlign: 'right' }}>{field}</th>)}
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    )
  }

  renderCell (html, isLastCell, popoverData) {
    const content = <div
      dangerouslySetInnerHTML={{ __html: html }}
      style={{ fontWeight: isLastCell ? 'bold' : 'normal' }}
    />
    if (!popoverData) {
      return content
    }

    // If we need Popover
    const popoverRows = Object.entries(popoverData)
      .map((row, i) => (
        <tr key={i}>
          <td style={{ textAlign: 'left' }}>{row[0]}</td>
          <td style={{ textAlign: 'right' }} dangerouslySetInnerHTML={{ __html: row[1] }}/>
        </tr>
      ))

    return (
      <div className={'d-popover-wrapper'}>
        {content}
        <div className={'d-popover-content'}>
          <table className={'table-stripped'}>
            <tbody>{popoverRows}</tbody>
          </table>
        </div>
      </div>
    )

  }
}
