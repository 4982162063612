import React from 'react'

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError (error) {
    // Update state so next render shows fallback UI.
    return { hasError: true }
  }

  componentDidCatch (error, info) {
    // log error if need
  }

  render () {
    if (!this.state.hasError) {
      return this.props.children
    }
    return (
      <div style={{
        display: 'flex',
        opacity: '0.5',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
        <h3>Something went wrong</h3>
        <div>A client error occurred</div>
      </div>
    )
  }
}
