import React, { Component } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  withStyles,
} from '@material-ui/core'
import { Button } from 'react-admin'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './UserUpdateModalStyles.js'
import Input from '../../other/Input'
import NumberInput from '../../other/NumberInput'
import { FactoryValidation } from '../../../FactoryValidation'

const typeOpts = [
  { value: 'test', title: 'Test' },
  { value: 'transfer', title: 'Transfer' }
]

const typeOptsForNonFriend = typeOpts.filter(item => item.value === 'transfer')

class BalanceUpdateModal extends Component {
  static propTypes = {
    cashiersActions: PropTypes.object,
    currentUser: PropTypes.object,
    isOpen: PropTypes.bool,
    updateModalState: PropTypes.func.isRequired,
    id: PropTypes.string,
    label: PropTypes.string
  }

  static defaultProps = {
    currentUser: {}
  }

  state = {
    tab: 0,
    isOpen: false,
    type: 'transfer',
    value: '',
    description: '',
    error: {
      value: ''
    },
  }

  componentWillReceiveProps (nextProps) {
    const { currentUser } = nextProps

    if (Object.keys(currentUser).length) {
      this.setState({
        isOpen: nextProps.isOpen,
        id: currentUser.id
      })
    }
  }

  handleChangeSelect = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleCloseClick = () => {
    // Reset form
    this.setState({
      tab: 0,
      type: 'transfer',
      value: '',
      description: '',
    })
    this.props.updateModalState('editBalance', false)
  }

  handleChangeTab = (event, value) => {
    this.setState({
      tab: value,
      type: 'transfer'
    })
  }

  handleUpdate = async (event) => {
    event.preventDefault()
    try {
      const { value, type, description, tab, } = this.state
      const { cashiersActions: { addTransaction }, id } = this.props

      const advancedRules = {
        value: ['required', { 'min_number': 0 }],
      }
      const errors = FactoryValidation({ value }, { ...advancedRules })

      if (errors) {
        return this.setState(prevState => ({
          error: {
            ...prevState.error,
            ...errors
          }
        }))
      }

      const data = {
        type,
        fiatAmount: value,
        currency: this.props.franchiseeFiat,
        description,
        direction: tab === 0 ? 'deposit' : 'withdraw'
      }
      await addTransaction(id, data)
    } catch (e) {
      console.warn('fail update balance', e)
    }

    this.handleCloseClick()
  }

  handleChange = (_, type, value) => {
    const { error } = this.state

    this.setState({
      [type]: value,
      error: {
        ...error,
        [type]: ''
      }
    })
  }

  render () {
    const { value, error, type, description, tab } = this.state
    const { classes, isOpen, currentUser } = this.props

    // kludge
    const opts = window.curUserIsFriend ? typeOpts : typeOptsForNonFriend

    const typeLabels = opts.map(({ value, title }) => (
      <FormControlLabel value={value} key={value} control={<Radio/>} label={title}/>
    ))

    return (
      <Dialog
        fullWidth
        open={isOpen}
        onClose={this.handleCloseClick}
      >
        <Paper square>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChangeTab}
            centered
          >
            <Tab label="Deposit"/>
            <Tab label="Withdraw"/>
          </Tabs>
        </Paper>
        <DialogTitle>
          Update balance
        </DialogTitle>
        <DialogContent>
          <div className={cn(classes.UserUpdateModal)}>
            <div className={cn(classes.inputWrapper)}>
              <span className={cn(classes.label)}>Value</span>
              <NumberInput
                type="value"
                placeholder="Value"
                value={value}
                onChange={this.handleChange}
                errorText={error.value}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)} style={{ justifyContent: 'flex-start', width: '83%' }}>
              <span className={cn(classes.label)}>
                  Type payment
              </span>
              <RadioGroup
                row
                name="type"
                style={{ width: '75%' }}
                value={type}
                onChange={this.handleChangeSelect}
              >
                {typeLabels}
              </RadioGroup>
            </div>
            <div className={cn(classes.inputWrapper)} style={{ justifyContent: 'flex-start', width: '83%' }}>
              <span className={cn(classes.label)}>Currency</span>
              <span style={{ width: '77%' }}>{currentUser.fiat ? currentUser.fiat.toUpperCase() : '--'}</span>
            </div>
            <div className={cn(classes.inputWrapper)}>
                            <span className={cn(classes.label)}>
                                Description
                            </span>
              <Input
                type="description"
                placeholder="Description"
                value={description}
                onChange={this.handleChange}
                errorText={error.description}
                className={cn(classes.label)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleUpdate}
            label="Save"
            className={cn('ra-delete-button')}
            key="button"
          >
            <EditIcon/>
          </Button>
          <Button label="ra.action.cancel" onClick={this.handleCloseClick}/>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(BalanceUpdateModal)


