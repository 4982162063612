import { CRUD_GET_LIST_SUCCESS } from 'react-admin'
import { WITHDRAW_TABLE_ACTION_SUCCESS } from './withdrawsActions'

export default (previousState = {
  serverBlocked: false
}, { type, payload }) => {

  switch (type) {
    case WITHDRAW_TABLE_ACTION_SUCCESS:
      return { ...previousState, serverBlocked: !!+payload.state }

    case CRUD_GET_LIST_SUCCESS:
      if (payload && payload.type === 'withdraws') {
        return { ...previousState, serverBlocked: !!+payload.state }
      } else {
        return previousState
      }

    default:
      return previousState
  }
};
