import React, { Component } from 'react'
import ReactDataGrid from 'react-data-grid'
import { Checkbox } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { changeListParams, crudUpdate, startUndoable } from 'ra-core'

class EditableDatagrid extends Component {
  static defaultProps = {
    columns: [],
    data: {},
    ids: [],
    selectedIds: []
  }

  state = {
    rows: [],
    checkBoxState: {
      package: {},
      paid: {}
    }
  }

  constructor (props) {
    super(props)
    this.perPageInitial = this.props.perPage
    this.loading = false
  }

  componentWillReceiveProps (nextProps) {
    if (
      nextProps.ids.length !== this.props.ids.length ||
      nextProps.isLoading !== this.props.isLoading ||
      nextProps.isDisabled !== this.props.isDisabled
    ) {
      this.loading = false

      this.setState({
        rows: nextProps.data,
      })
    }
  }

  componentWillUnmount () {
    this.props.changeListParams(this.props.resource, {
      ...this.props.params,
      perPage: this.perPageInitial,
    })
  }

  rowGetter = index => {
    const { ids } = this.props
    const { rows } = this.state

    if (rows[ids[index]]) {
      const data = rows[ids[index]]
      return {
        ...data,
        fiat: data.fiat.toUpperCase(),
        name: data.franchisee.name
      }
    }

    return {}
  }

  onChangeCheckBox = (id, type, value) => {
    const { checkBoxState } = this.state
    this.setState({
      checkBoxState: {
        ...checkBoxState,
        [type]: {
          ...checkBoxState[type],
          [id]: value
        }
      }
    })

    this.props.onChange({ id, ...{ [type]: Number(value) } })
  }

  renderActionButton = (column, row) => {
    const { checkBoxState } = this.state
    const type = column.key
    // eslint-disable-next-line
    const currentVal = checkBoxState[type] && checkBoxState[type][row.id] || null

    return <Checkbox
      checked={currentVal}
      disabled={this.props.isDisabled}
      onChange={(_, v) => {this.onChangeCheckBox(row.id, type, v)}}
    />
  }

  getCellActions = (column, row) => {
    const cellActions = {
      package: [{
        icon: this.renderActionButton(column, row)
      }],
      paid: [{
        icon: this.renderActionButton(column, row)
      }]
    }

    return cellActions[column.key]
  }

  handleGridSort = (sortColumn, sortDirection) => {
    //this.props.setSort(sortColumn, sortDirection);
  }

  // handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
  //   const { ids, onChange } = this.props
  //
  //   this.setState(state => {
  //     const rows = state.rows
  //     const isEmptyData = Object.values(updated).length === 0
  //     const initDataObject = { paid: '0', package: '' }
  //
  //     for (let i = fromRow; i <= toRow; i++) {
  //       const id = ids[i]
  //       let rowToUpdate = rows[id]
  //
  //       rows[id] = { ...rowToUpdate, ...updated }
  //
  //       if (!isEmptyData) {
  //         onChange({ id: rows[id].id, ...{ ...initDataObject, ...updated } })
  //       }
  //     }
  //     return { rows }
  //   })
  // }

  handleScroll = ({ scrolTop }) => {}
  handleRowSelect = rows => {
    this.props.onSelect(rows.map(row => row.id))
  }

  handleCheckIsEditable = () => {
    return this.props.isDisabled
  }

  render () {
    const { minHeight, total, columns } = this.props

    return (
      <ReactDataGrid
        // enableRowSelect={true}
        // enableCellSelect={true}
        columns={columns}
        rowGetter={this.rowGetter}
        rowsCount={total}
        onScroll={this.handleScroll}
        isScrolling={true}
        // sortColumn={currentSort.field}
        // sortDirection={currentSort.order}
        // onRowSelect={this.handleRowSelect}
        // onGridRowsUpdated={this.handleGridRowsUpdated}
        // onGridSort={this.handleGridSort}
        minHeight={minHeight}
        getCellActions={this.getCellActions}
        // onCheckCellIsEditable = { this.handleCheckIsEditable }
      />
    )
  }
}

EditableDatagrid.defaultProps = {
  minHeight: 580,
}

const mapStateToProps = (state, props) => {
  return {
    params: state.admin.resources[props.resource].list.params,
    isDisabled: !state.withdrawsState.serverBlocked
  }
}

export default compose(
  // withStyles(styles),
  connect(
    mapStateToProps,
    {
      changeListParams,
      dispatchCrudUpdate: crudUpdate,
      startUndoable,
    }
  )
)(EditableDatagrid)
