import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function IconBitmex (props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 312.41 195.69" xmlns="http://www.w3.org/2000/svg"><path d="m107.52 0h118.16a7.78 7.78 0 0 1 -1.55 4.32c-4 6.86-7.6 14-11.53 20.86-5.6 9.82-10.76 19.88-16.41 29.69-3.58 7.06-7.62 13.9-11.44 20.84h-68.88c-1.2 0-2.39.06-3.59.13-1 2.64-2.66 5-4 7.43-1.75 2.84-3.17 5.87-4.9 8.71-1.83 3.82-4.36 7.24-6.2 11-3.8 6.45-7.35 13-11.07 19.52q-5.82 10-11.38 20.28c-1.57 2.43-3 4.94-4.33 7.51-7.77 13.57-15.29 27.21-23.07 40.71a16.39 16.39 0 0 1 -2.58 4c-13.3-.09-26.6 0-39.9 0a20.34 20.34 0 0 0 -4.85.68c0-3.68 2.46-6.62 4.12-9.68 2-4.15 4.67-8 6.68-12.16 15.94-28.52 31.71-57.14 47.6-85.68 3.6-6.2 6.69-12.53 10.31-18.61 4.52-8 8.73-16.18 13.44-24.09 1.5-3.31 3.52-6.34 5.17-9.57 5.86-10.43 11.61-20.92 17.45-31.35a18.11 18.11 0 0 1 2.75-4.54z" fill="#eb4a60"/><path d="m244.44 42c7.87-13.75 15.47-27.65 23.45-41.34h42.68a8.82 8.82 0 0 1 1.84.29c-1.42 2.15-2.61 4.43-3.86 6.67q-15.44 27.71-30.79 55.45c-4.3 7.53-8.3 15.23-12.66 22.72l-.43.15-.08.94q-30.1 54-60 108.08-58.77.04-117.59.04c2.23-4.66 4.87-9.11 7.35-13.64 11.2-20.57 22.65-40.91 33.65-61.43q36.1-.06 72.22 0c14.87-25.93 29.42-52 44.18-77.93z" fill="#595fb5"/></svg>
    </SvgIcon>
  )
}
