import React from 'react'
import BaseModule from '../BaseModule'
import { TRANSFERS_ACTION } from '../Dashboard1'

const ACTIONS = [
  { action: 'bump', key: 'bumpable' },
]

export default class ModuleTransfers extends BaseModule {

  renderBody (data) {
    const rows = data.map((row, i) => {
      const buttons = ACTIONS
        .filter(({ key }) => row[key])
        .map(({ key, action }) => (
          <button
            key={action}
            type={'button'}
            className={'tx-action-btn'}
            onClick={() => this.action(action, row.uuid)}
            style={{ whiteSpace: 'nowrap' }}
          >
            {row[key]}
          </button>
        ))

      return (
        <tr key={i} style={{ color: row.color }}>
          <td><span dangerouslySetInnerHTML={{ __html: row.source }}/></td>
          <td><span dangerouslySetInnerHTML={{ __html: row.target }}/></td>
          <td>
            <span dangerouslySetInnerHTML={{ __html: row.amount }}/>&nbsp;
            <span dangerouslySetInnerHTML={{ __html: row.currency }}/>
          </td>
          <td><span dangerouslySetInnerHTML={{ __html: row.state }}/></td>
          <td><span dangerouslySetInnerHTML={{ __html: row.sendingTime }}/></td>
          <td><span dangerouslySetInnerHTML={{ __html: row.receivedTime }}/></td>
          <td>{buttons}</td>
        </tr>
      )
    })

    return (
      <table className="table table-stripped">
        <tbody>{rows}</tbody>
      </table>
    )
  }

  action (action, id) {
    const data = { [action]: id }
    this.fetchData(TRANSFERS_ACTION, data)
      .then(data => {
        this.onDataLoad(data, true)
      })
  }
}
