import BaseDashboard from './BaseDashboard'
import ModuleSimpleTable from './modules/ModuleSimpleTable'

// routes
export const GET_BITMEX_POSITION = 'GET_BITMEX_POSITION'
export const GET_BITMEX_HISTORY = 'GET_BITMEX_HISTORY'

export default class Dashboard2_Bitmex extends BaseDashboard {

  title = 'Bitmex'

  modules = [
    { id: 'reset' },
    { id: 'position', component: ModuleSimpleTable, route: GET_BITMEX_POSITION },
    { id: 'history', component: ModuleSimpleTable, route: GET_BITMEX_HISTORY }
  ]

  moduleStylesDefault = {
    reset: 'left: 0; top: 0; width: 32px; height: 32px',
    position: 'left: 10px; top: 40px; width: 300px; height: 300px',
    history: 'left: 320px; top: 40px; width: 300px; height: 300px',
  }
}
