import BaseDashboard from './BaseDashboard'
import ModuleWalletOutputs from './modules/ModuleWalletOutputs'

// routes
export const WALLET_OUTPUTS = 'WALLET_OUTPUTS'

export default class Dashboard9_Outputs extends BaseDashboard {

  title = 'Outputs'

  modules = [
    { id: 'reset' },
    { id: 'walletOutputs', component: ModuleWalletOutputs, route: WALLET_OUTPUTS },
  ]

  moduleStylesDefault = {
    reset: 'left: 0; top: 0; width: 32px; height: 32px',
    walletOutputs: 'left: 10px; top: 40px;  width: 800px; height: 500px',
  }
}
