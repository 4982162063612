import React from 'react'
import BaseModule from '../BaseModule'

import 'flatpickr/dist/themes/material_blue.css'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import { POST_TRADE_INTERFACE } from '../Dashboard4_Transfery'
import Select from 'react-select'
import { selectStyles } from '../select-styles'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

/**
 * key-values object to divs
 */
function objectToBlock (object) {
  const rows = Object.entries(object)
    .map(([key, value]) => <div key={key}>{key}: {value}</div>)

  return (
    <div style={{ display: 'inline-block', textAlign: 'left' }}>
      {rows}
    </div>
  )
}

export default class ModuleTradeInterface extends BaseModule {

  handleDialogClose = () => {
    const dialog = { show: false }
    this.setState({ dialog })
  }

  constructor (props) {
    super(props)
    this.state = {
      ...this.state,
      dialog: {
        show: false,
        title: '',
        dataId: null,
        data: null,
        error: null
      }
    }
  }

  showDialog (data) {
    const dialog = {
      show: true,
      title: data ? 'Edit order' : 'New order',
      dataId: data ? data.id : null,
      data: {
        value: data ? data.value : '',
        coinFrom: data ? data.coinFrom : null,
        coinTo: data ? data.coinTo : null,
        chunk: data ? data.chunk : ''
      },
      error: null
    }
    this.setState({ dialog })

    // Fix flatpickr bug - remove tabindex="-1" from dialog
    function removeNegativeTabIndex () {
      const el = document.querySelector('div[role="dialog"] div[tabindex="-1"]')
      if (el) {
        el.removeAttribute('tabindex')
      }
    }

    setTimeout(removeNegativeTabIndex, 200)
    setTimeout(removeNegativeTabIndex, 500)
  }

  updateDialogData (field, value) {
    const { dialog } = this.state
    dialog.data[field] = value
    this.setState({ dialog })
  }

  validateDialogData () {
    const { dialog } = this.state
    dialog.error = null

    const data = dialog.data

    if (data.value === '') {
      dialog.error = 'Value required'
    } else if (!data.coinFrom) {
      dialog.error = 'Coin From required'
    } else if (!data.coinTo) {
      dialog.error = 'Coin To required'
    }

    this.setState({ dialog })
    return dialog.error === null
  }

  saveDialogData () {
    if (this.validateDialogData()) {
      const { dialog } = this.state

      const query = dialog.dataId
        ? { editOrder: dialog.dataId }
        : { setOrder: true }

      const data = dialog.data
      const postData = {
        value: data.value,
        coinFrom: data.coinFrom,
        coinTo: data.coinTo,
        chunk: data.chunk
      }

      return this.fetchData(POST_TRADE_INTERFACE, { query, data: postData })
        .then(data => {
          this.handleDialogClose()
          this.onDataLoad(data, true)
        })
    }
  }

  renderDialog (availableCoins) {
    const { dialog } = this.state

    let dialogContent = null

    if (dialog.data) {
      const coinsFromOpts = availableCoins.from.map(x => ({ value: x, label: x }))
      const coinsToOpts = availableCoins.to.map(x => ({ value: x, label: x }))

      const coinFromOpt = coinsFromOpts.find(x => x.value === dialog.data.coinFrom)
      const coinToOpt = coinsToOpts.find(x => x.value === dialog.data.coinTo)

      dialogContent = (
        <DialogContent className={'trade-interface-dialog-content'}>

          <div>
            <span className={'label'}>Coin From</span>
            <Select options={coinsFromOpts}
                    value={coinFromOpt}
                    onChange={value => this.updateDialogData('coinFrom', value.value)}
                    styles={selectStyles}
            />
          </div>

          <div>
            <span className={'label'}>Coin To</span>
            <Select options={coinsToOpts}
                    value={coinToOpt}
                    onChange={value => this.updateDialogData('coinTo', value.value)}
                    styles={selectStyles}
            />
          </div>

          <div>
            <span className={'label'}>Value</span>
            <input
              type={'number'}
              value={dialog.data.value}
              onChange={e => this.updateDialogData('value', e.target.value)}
            />
          </div>

          <div>
            <span className={'label'}>Chunk</span>
            <input
              type={'number'}
              value={dialog.data.chunk}
              onChange={e => this.updateDialogData('chunk', e.target.value)}
            />
          </div>

          <div className={'errors'}>{dialog.error || ''}</div>

        </DialogContent>
      )
    }

    return (
      <Dialog
        className={'trade-interface-dialog'}
        open={dialog.show}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.handleDialogClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><span>{dialog.title}</span></DialogTitle>
        {dialogContent}
        <DialogActions>
          <Button onClick={() => this.saveDialogData()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderBody ({ orders, availableCoins }) {
    const tableRows = orders.map((row, i) => {
      const availableBtns = {
        start: row.isStartable,
        stop: row.isStopable,
        edit: row.isEditeable
      }

      const buttons = ['start', 'stop', 'edit']
        .filter(action => availableBtns[action])
        .map(action => (
          <button
            key={action}
            type={'button'}
            className={'trade-interface-btn'}
            onClick={() => this.action(action, row)}
          >
            {action}
          </button>
        ))

      return (
        <tr key={i} style={{background: row.color}}>
          <td>{row.id}</td>
          <td>{row.coinFrom}</td>
          <td>{row.coinTo}</td>
          <td>{row.chunk}</td>
          <td>{row.value}</td>
          <td>{row.trades ? objectToBlock(row.trades) : null}</td>
          <td>{row.done && row.done.out ? objectToBlock(row.done.out) : null}</td>
          <td>{row.done && row.done.in ? objectToBlock(row.done.in) : null}</td>
          <td>{row.alreadyDone}</td>
          <td>{buttons}</td>
        </tr>
      )
    })

    return (
      <div className={'mod-trade-interface'}>

        {this.renderDialog(availableCoins)}

        <table>
          <thead>
          <tr>
            <th>ID</th>
            <th>coinFrom</th>
            <th>coinTo</th>
            <th>chunk</th>
            <th>value</th>
            <th>trades</th>
            <th>OUT</th>
            <th>IN</th>
            <th>%</th>
            <th>
              <button
                type={'button'}
                className={'trade-interface-btn'}
                onClick={() => this.showDialog(null)}
              >
                Add new
              </button>
            </th>
          </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    )
  }

  action (action, row) {
    switch (action) {
      case 'start':
        return this.fetchData(POST_TRADE_INTERFACE, { query: { startsOrder: row.id } })
          .then(data => this.onDataLoad(data, true))
      case 'stop':
        return this.fetchData(POST_TRADE_INTERFACE, { query: { stopOrder: row.id } })
          .then(data => this.onDataLoad(data, true))
      case 'edit':
        this.showDialog(row)
        return
      default:
        console.error('Unexpected')
    }
  }

}
