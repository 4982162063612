import { SET_CURRENT_USER_DATA, SET_MODAL_STATE, } from './usersActions'

export default (previousState = {
  currentUserId: null,
  currentUserName: null,
  currentUserFiat: null
}, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_USER_DATA:
      return {
        ...previousState,
        currentUserId: payload.id,
        currentUserName: payload.name,
        currentUserFiat: payload.fiat,
      }
    case SET_MODAL_STATE: {
      return {
        ...previousState, modalType: payload.type, modalOpen: payload.state
      }
    }
    default:
      return previousState
  }
};
