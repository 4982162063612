import React from 'react'
import BaseModule from '../BaseModule'
import { flattenObject } from '../../../utils/utils'

export default class ModuleLoansInfo extends BaseModule {

  // Imitate "standard" module data
  dataResolver = data => ({
    name: 'Loans info',
    data,
  })

  renderBody (allData) {
    const { translations, data } = allData

    if (data.data.length === 0) {
      return <div>--</div>
    }

    const flattenTranslations = flattenObject(translations)
    const headerCells = Object.keys(flattenObject(data.data[0]))
      .map((key, i) => (
        <th key={i}>{flattenTranslations[key]}</th>
      ))

    const rows = data.data.map((rowData, i) => {
      const cells = Object.values(flattenObject(rowData))
        .map((html, i) => (
          <td
            key={i}
            style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ))
      return (
        <tr key={i}>{cells}</tr>
      )
    })

    return (
      <table className={'table-stripped table-stripped_cells-splitted'} style={{ width: '100%' }}>
        <thead>
        <tr>{headerCells}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    )
  }
}
