import React from 'react'
import BaseModule from '../BaseModule'
import Select from 'react-select'
import { POST_MANUAL_ORDER } from '../Dashboard4_Transfery'
import { selectStyles } from '../select-styles'

export default class ModuleManualOrders extends BaseModule {

  constructor (props) {
    super(props)
    this.state = {
      ...this.state,
      currency: null,
      cryptoAmount: ''
    }
  }

  renderBody ({ data, formData }) {
    const currencyOpts = formData.currency.map(option => ({ value: option, label: option }))

    const submit = () => {
      const { currency, cryptoAmount } = this.state
      this.fetchData(POST_MANUAL_ORDER, { currency, cryptoAmount })
        .then(data => {
          this.onDataLoad(data, true)
        })
    }

    const tableRows = data.map(row => (
      <tr key={row.id}>
        <td>{row.id}</td>
        <td>{row.currency}</td>
        <td>{row.amount}</td>
        <td>{row.stampInsert}</td>
      </tr>
    ))

    const { currency, cryptoAmount } = this.state
    const isFormValid = currency && cryptoAmount

    return (
      <div className={'mod-manual-orders'}>
        <div style={{ display: 'inline-block', width: '50%' }}>
          <div>Co</div>
          <Select options={currencyOpts}
                  onChange={e => this.setState({ currency: e.value })}
                  styles={selectStyles}
          />
        </div>

        <div style={{ display: 'inline-block', width: '47%', marginLeft: '3%' }}>
          <div>Ile</div>
          <div>
            <input type="text" style={{ width: '95%' }}
                   onChange={e => this.setState({ cryptoAmount: +e.target.value })}
            />
          </div>
        </div>

        <div style={{ textAlign: 'right', marginTop: '5px' }}>
          <button type="button"
                  className={'btn'}
                  style={{ display: 'inline-block' }}
                  disabled={!isFormValid}
                  onClick={submit}
          >Submit
          </button>
        </div>

        <table style={{ marginTop: '15px', width: '100%' }}>
          <thead>
          <tr>
            <th>Id</th>
            <th>Coin</th>
            <th>Wartosc</th>
            <th>Czas</th>
          </tr>
          </thead>
          <tbody>
          {tableRows}
          </tbody>
        </table>
      </div>
    )
  }
}
