import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core'
import classnames from 'classnames'
import { red } from '@material-ui/core/colors'

const styles = theme => ({
  root: {
    minWidth: 200,
    color: red[800],
    marginRight: 40,
    lineHeight: '40px'
  }
})

class NotificationLabel extends Component {
  render () {
    const { classes = {}, isShow } = this.props

    return (
      <Fragment>
        {
          isShow ?
            <span className={classnames(classes.root)}>Please press Unblock when finished processing!</span>
            : null
        }
      </Fragment>
    )
  }
}

NotificationLabel.propTypes = {
  classes: PropTypes.object,
  isShow: PropTypes.bool
}

export default compose(
  withStyles(styles)
)(NotificationLabel)
