

export const WITHDRAW_TABLE_ACTION = 'WITHDRAW_TABLE_ACTION'
export const WITHDRAW_TABLE_ACTION_SUCCESS = 'WITHDRAW_TABLE_ACTION_SUCCESS'
export const WITHDRAW_TABLE_ACTION_FAILURE = 'WITHDRAW_TABLE_ACTION_FAILURE'

export function setTableAction (action) {
  return ({
    type: WITHDRAW_TABLE_ACTION,
    payload: { action },
    meta: { resource: 'masterListWithdraws', fetch: WITHDRAW_TABLE_ACTION }
  })
}

export const SUBMIT_WITHDRAWS = 'SUBMIT_WITHDRAWS'
export const SUBMIT_WITHDRAWS_SUCCESS = 'SUBMIT_WITHDRAWS_SUCCESS'
export const SUBMIT_WITHDRAWS_FAILURE = 'SUBMIT_WITHDRAWS_FAILURE'

export function submitWithdraws (data) {
  return ({
    type: SUBMIT_WITHDRAWS,
    payload: { data },
    meta: { resource: 'masterListWithdraws', fetch: SUBMIT_WITHDRAWS }
  })
}
