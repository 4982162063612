import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function IconVM (props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <text x="0" y="18" fontSize="16px" fontWeight="bold">VM</text>
      </svg>
    </SvgIcon>
  )
}
