import React from 'react'
import BaseModule, { DEFAULT_RELOAD_INTERVAL } from '../BaseModule'
import restApi from '../../../../api/restApi'
import { CANCEL_BINANCE_ACTION } from '../Dashboard8_BinanceLoans'

const CANCELABLE_STATES = [
  'accepted',
  'started',
  'waiting',
]

const FAST_RELOAD_INTERVAL = 10

export default class ModuleBinanceActions extends BaseModule {

  form = {
    amount: '',
    timeout: '',
  }

  constructor (props) {
    super(props)

    // Hack (to have possibility to reload this module from another)
    window.moduleBinanceActions = this
  }

  destroy () {
    window.moduleBinanceActions = null
  }

  // Imitate "standard" module data
  dataResolver = data => {
    let interval = DEFAULT_RELOAD_INTERVAL
    for (const item of data) {
      if (CANCELABLE_STATES.includes(item.state)) {
        interval = FAST_RELOAD_INTERVAL
        break
      }
    }

    return {
      name: 'Actions',
      data,
      interval,
    }
  }

  renderBody (data) {
    const rows = data.map(item => {
      let button = null
      if (CANCELABLE_STATES.includes(item.state)) {
        button = (
          <button className="btn btn_small" onClick={() => this.cancelAction(item.id)}>cancel</button>
        )
      }
      const date = new Date(item.posixTime * 1000)

      return (
        <tr
          key={item.id}
          title={item.stateDetails}
        >
          <td>{item.id}</td>
          <td>{date.toLocaleDateString()} {date.toLocaleTimeString()}</td>
          <td>{item.exchange}</td>
          <td>{item.type}</td>
          <td>{item.loanCurrency}</td>
          <td>{item.collateralCurrency}</td>
          <td>{item.amount}</td>
          <td>{item.state}</td>
          <td>{button}</td>
        </tr>
      )
    })

    return (
      <table className="table table-stripped">
        <thead>
        <tr>
          <th>ID</th>
          <th>Time</th>
          <th>Exchange</th>
          <th>Type</th>
          <th>Loan</th>
          <th>Collateral</th>
          <th>Amount</th>
          <th>State</th>
          <th></th>
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    )
  }

  cancelAction (id) {
    restApi(CANCEL_BINANCE_ACTION, '', { id })
      .then(() => this.reloadData())
  }
}
