import { AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin'
import AppConfig from '../config'
import { wsClose, wsConnect } from './wsApi'
import { showErrorToastr } from '../toastr'

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password, ga } = params

    let formData = new FormData()
    formData.append('email', username)
    formData.append('passwd', password)

    if (ga) formData.append('gaKod', ga)

    const request = new Request(`${AppConfig.apiUrl}/masterLogin.php`, {
      method: 'POST',
      body: formData
    })

    return fetch(request)
      .then(async response => {
        if (response.status < 200 || response.status >= 300) {
          const json = await response.json()
          showErrorToastr(`Error ${response.status}`, json && json.msg ? json.msg : null)
          throw new Error(response.statusText)
        }

        const json = await response.json()
        if (json && json.user && json.user.rights) {
          sessionStorage.setItem('userRights', json.user.rights.join(','))
        } else {
          console.error(`Can't parse user rights`)
        }

        if (json && json.user && json.user.currencies) {
          sessionStorage.setItem('currencies', JSON.stringify(json.user.currencies))
        }

        const token = response.headers.get('Auth-Token')

        sessionStorage.setItem('token', token)
        sessionStorage.setItem('loginToken', token)

        wsConnect()
      })
  }

  if (type === AUTH_LOGOUT) {

    // this can be called on page first load (after AUTH_CHECK)
    // so we don't send logout request not login-ed yet
    const token = sessionStorage.getItem('token')
    if (token) {
      const params = {
        method: 'GET',
        'headers': new Headers({
          'Auth-Token': token
        })
      }
      const request = new Request(`${AppConfig.apiUrl}/masterLogout.php`, params)

      fetch(request).then(null)

      sessionStorage.removeItem('token')
      sessionStorage.removeItem('loginToken')

      wsClose()
    }

    return Promise.resolve()
  }

  if (type === AUTH_ERROR) {
    const status = params.status
    if (status === 401 || status === 403) {
      sessionStorage.removeItem('token')
      return Promise.reject()
    }
    return Promise.resolve()
  }

  if (type === AUTH_CHECK) {
    return sessionStorage.getItem('token') ? Promise.resolve() : Promise.reject()
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const userRights = sessionStorage.getItem('userRights')
    if (userRights) {
      return userRights
    } else {
      // Can't get user rights. Allow all resources
      return 'ALL'
    }
  }

  return Promise.resolve()
}
