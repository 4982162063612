import BaseDashboard from './BaseDashboard'
import ModuleLoansInfo from './modules/ModuleLoansInfo'
import ModuleBinanceActions from './modules/ModuleBinanceActions'
import ModuleBinanceForm from './modules/ModuleBinanceForm'

// routes
export const GET_LOANS_INFO = 'GET_LOANS_INFO'
export const GET_BINANCE_ACTIONS = 'GET_BINANCE_ACTIONS'
export const GET_BINANCE_ACTIONS_VALUES = 'GET_BINANCE_ACTIONS_VALUES'
export const CANCEL_BINANCE_ACTION = 'CANCEL_BINANCE_ACTIONS'
export const POST_BINANCE_FORM = 'POST_BINANCE_FORM'

export default class Dashboard8_BinanceLoans extends BaseDashboard {

  title = 'Binance-Loans'

  modules = [
    { id: 'reset' },
    { id: 'loansInfo', component: ModuleLoansInfo, route: GET_LOANS_INFO },
    { id: 'actions', component: ModuleBinanceActions, route: GET_BINANCE_ACTIONS },
    { id: 'binanceForm', component: ModuleBinanceForm, route: GET_BINANCE_ACTIONS_VALUES },
  ]

  moduleStylesDefault = {
    reset: 'left: 0; top: 0; width: 32px; height: 32px',
    loansInfo: 'left: 10px; top: 40px;  width: 780px; height: 400px',
    actions: 'left: 800px; top: 40px;  width: 700px; height: 400px',
    binanceForm: 'left: 800px; top: 450px;  width: 700px; height: 200px',
  }
}
