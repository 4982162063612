import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { withStyles } from '@material-ui/core'
import { TransitionGroup } from 'react-transition-group'
import { Fade } from '../utils/animations.js'

import styles from './InputStyles.js'

class Input extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    errorText: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool
  }

  handleChange = e => {
    const { onChange, type, name } = this.props

    if (onChange) onChange(name, type, e.target.value)
  }

  handleBlur = e => {
    const { onBlur, type } = this.props

    if (onBlur) onBlur(type, e.target.value)
  }

  _getType () {
    const { type } = this.props

    return type ? type : 'text'
  }

  render () {
    const { classes, value, placeholder, errorText, className, defaultValue, name, id, checked, disabled, min, step, max } = this.props

    return (
      <div className={cn(classes.Input, classes[className])}>
        <input
          className={cn(classes[className])}
          defaultValue={defaultValue}
          id={id}
          value={value}
          name={name}
          checked={checked}
          disabled={disabled}
          type={this._getType()}
          step={step}
          min={min}
          max={max}
          placeholder={placeholder}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
        />
        <TransitionGroup>
          {
            errorText
              ? <Fade>
                <div className={classes.alert}>
                  {errorText}
                </div>
              </Fade>                                      // eslint-disable-line
              : null
          }
        </TransitionGroup>
      </div>
    )
  }
}

export default withStyles(styles)(Input)
