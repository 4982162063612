import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, withStyles } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { Button } from 'react-admin'
import IconResetPassword from './IconResetPassword'
import ButtonWithConfirm from './ButtonWithConfirm'
import IconLockOpen from '@material-ui/icons/LockOpen'
import IconLock from '@material-ui/icons/Lock'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

const TCell = withStyles({
  root: {
    padding: '0 12px'
  }
})(TableCell)

const tableHeadFields = {
  cashiers: [
    { id: 'nickname', label: 'Nick Name', sortable: true },
    { id: 'email', label: 'Email', sortable: true },
    { id: 'percent.value', label: 'Percent', sortable: true },
    { id: 'turnover', label: 'Turnover', sortable: true },
    { id: 'txNumber.finished', label: 'Tx finished', sortable: true },
    { id: 'block', label: '' },
    { id: 'resetPwd', label: 'Reset passwd' },
    { id: '2FA', label: '2FA', thAlign: 'center' },
    { id: 'edit', label: '' }
  ],
  transactions: [
    { id: 'date', label: 'Fiat Paid' },
    { id: 'type', label: '' },
    { id: 'crypto', label: 'Crypto' },
    { id: 'fiat', label: 'Fiat' },
    { id: 'tx', label: 'Transactions' },
    { id: 'invoice', label: 'Invoice' },
    { id: 'description', label: 'Opis' }
  ]
}

class EnhancedTableHead extends React.Component {
  static propTypes = {
    fields: PropTypes.array.isRequired,
    requestSort: PropTypes.func,
    sortParams: PropTypes.object
  }

  render () {
    const { fields, sortParams } = this.props

    return (
      <TableHead>
        <TableRow>
          {fields.map((field, i) => (
            <TCell key={i}>
              {field.sortable ?
                <TableSortLabel
                  active={sortParams && sortParams.orderBy === field.id}
                  direction={sortParams ? sortParams.order.toLowerCase() : 'asc'}
                  onClick={() => this.props.requestSort(field.id)}
                >
                  {field.label}
                </TableSortLabel>
                :
                <div style={{ textAlign: field.thAlign || 'left' }}>{field.label}</div>
              }
            </TCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
}

const styles = () => ({
  root: {},
  table: {
    maxWidth: '75vw',
  },
  tableWrapper: {
    overflowX: 'auto',
    overflowY: 'auto',
    maxHeight: 'calc(90vh - 180px)'
  },
  MuiTCell: {
    root: {
      padding: '0 0'
    }
  }
})

class CustomTable extends React.Component {
  state = {
    data: this.props.data
  }

  editCashier = async (id) => {
    // We loading actual cashier data (in cashier4edit), then opening update modal
    this.props.cashiersActions.getCashier(id)
  }

  formatCashierPercent (percent) {
    return percent.type === 'fixed'
      ? `${percent.type}: ${percent.value}%`
      : `${percent.type}: ${percent.value}% [${percent.min}-${percent.max}]`
  }

  render () {
    const { classes, type, requestSort, sortParams, cashiersActions, currentUserFiat } = this.props
    const { cashierAction, resetCashierPassword } = cashiersActions
    const { data } = this.state

    const turnoverGetter = n => {
      const turnover = n.turnover[currentUserFiat]
      return turnover !== undefined
        ? `${turnover.toLocaleString()} ${currentUserFiat.toUpperCase()}`
        : ''
    }

    if (!data || !data.length) return null

    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-labelledby="tableTitle">
          <EnhancedTableHead
            fields={tableHeadFields[type]}
            sortParams={sortParams}
            requestSort={requestSort}
          />
          <TableBody>
            {data.map((n, i) => (
              type === 'cashiers' ?

                // ------ Cashiers Table Row: ------
                <TableRow
                  hover
                  tabIndex={-1}
                  key={i}
                >
                  <TCell>{n.nickname}</TCell>
                  <TCell>{n.email}</TCell>
                  <TCell>{n.percent ? this.formatCashierPercent(n.percent) : ''}</TCell>
                  <TCell>{turnoverGetter(n)}</TCell>
                  <TCell>{n.txNumber ? n.txNumber.finished : ''}</TCell>

                  <TCell>
                    <ButtonWithConfirm
                      onConfirm={() => cashierAction(n.id, n.isActive ? 'block' : 'unblock')}
                      button={<Button>{n.isActive ? <IconLockOpen/> : <IconLock/>}</Button>}
                    />
                  </TCell>
                  <TCell>
                    <ButtonWithConfirm
                      onConfirm={() => resetCashierPassword(n.id)}
                      button={<Button><IconResetPassword/></Button>}
                    />
                  </TCell>
                  <TCell>
                    <Switch
                      checked={n.secFA}
                      onChange={() => cashiersActions.cashierSwitch2FA(n.id, !n.secFA)}
                    />
                  </TCell>
                  <TCell>
                    <Button onClick={() => this.editCashier(n.id)}>
                      <EditIcon/>
                    </Button>
                  </TCell>
                </TableRow>

                :

                // ------ Transactions Table Row: ------
                <TableRow
                  hover
                  tabIndex={-1}
                  key={i}
                >
                  <TCell>{n.fiatPaid}</TCell>
                  <TCell>{n.type}</TCell>
                  <TCell
                    style={{ whiteSpace: 'nowrap' }}>{`${n.cryptoAmount.toLocaleString()} ${n.crypto.toUpperCase()}`}</TCell>
                  <TCell
                    style={{ whiteSpace: 'nowrap' }}>{`${n.quarkFiatAmount.toLocaleString()} ${n.fiat.toUpperCase()}`}</TCell>
                  <TCell>
                    {n.txLink ? <a href={n.txLink} target="_blank" rel="noopener noreferrer">Transaction</a> : null}
                  </TCell>
                  <TCell>
                    {n.invoices && n.invoices.fromFranchisor && n.invoices.fromFranchisor.link ?
                      <a href={n.invoices.fromFranchisor.link} target="_blank" rel="noopener noreferrer">Invoice URL</a>
                      : null
                    }
                  </TCell>
                  <TCell>{n.description}</TCell>
                  {/* <TCell ><CashierShowModal getCashier={getCashier} record = {cashier} cashierId = {n.id_cashier} /></TCell> */}
                </TableRow>

            ))}
          </TableBody>
        </Table>
      </div>

    )
  }
}

CustomTable.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  requestSort: PropTypes.func.isRequired,
  sortParams: PropTypes.object,
  cashiersActions: PropTypes.object.isRequired,
  currentUserFiat: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  return {
    currentUserFiat: state.usersState.currentUserFiat
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(CustomTable)
