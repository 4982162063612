import React from 'react'
import BaseModule from '../BaseModule'
import Select from 'react-select'
import { POST_TRANSFER } from '../Dashboard4_Transfery'
import { selectStyles } from '../select-styles'

export default class ModuleTransferForm extends BaseModule {

  transferForm = {
    source: null,
    target: null,
    currency: null,
    amount: ''
  }

  renderBody (data) {
    const sourceOpts = data.source.map(option => ({ value: option, label: option }))
    const targetOpts = data.target.map(option => ({ value: option, label: option }))
    const currencyOpts = data.currency.map(option => ({ value: option, label: option }))

    const submit = () => {
      this.fetchData(POST_TRANSFER, this.transferForm)
        .then(null)
    }

    return (
      <div className={'mod-transfer-form'}>

        <div>
          <div>Skad</div>
          <Select options={sourceOpts}
                  onChange={e => this.transferForm.source = e.value}
                  styles={selectStyles}
          />
        </div>

        <div>
          <div>Dokad</div>
          <Select options={targetOpts}
                  onChange={e => this.transferForm.target = e.value}
                  styles={selectStyles}
          />
        </div>

        <div>
          <div>Co</div>
          <Select options={currencyOpts}
                  onChange={e => this.transferForm.currency = e.value}
                  styles={selectStyles}
          />
        </div>

        <div>
          <div>Ile</div>
          <div>
            <input type="text" style={{ width: '95%' }}
                   onChange={e => this.transferForm.amount = e.target.value}
            />
          </div>
        </div>

        <div>
          <button type="button" className={'btn'} style={{ width: '100%', position: 'absolute', bottom: '0' }}
                  onClick={submit}
          >Submit transfer
          </button>
        </div>
      </div>
    )
  }
}
