import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { translate } from 'ra-core'
import { Button } from 'react-admin'

class ButtonWithConfirm extends Component {
  state = {
    showDialog : false
  };

  handleClick = () => {
    if (!this.props.disabled) {
      this.setState({ showDialog: true });
    }
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleConfirm = () => {
    this.setState({ showDialog: false });
    this.props.onConfirm()
  }

  render() {
    const { showDialog } = this.state;
    const { button } = this.props;

    return (
      <Fragment>
        <div onClick={this.handleClick} style={{display: 'inline-flex'}}>
          {button}
        </div>

        <Dialog
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label='Are you sure?'
        >
          <DialogContent>
            <div>{`ARE YOU SURE?`}</div>
          </DialogContent>
          <DialogActions style={{justifyContent: 'center'}}>
            <Button onClick={this.handleConfirm} label={'Yes'}/>
            <Button onClick={this.handleCloseClick} label={'No'}/>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

ButtonWithConfirm.propTypes = {
  disabled      : PropTypes.bool,
  button        : PropTypes.object,
  onConfirm     : PropTypes.func
};

export default compose(
  translate
)(ButtonWithConfirm);
