import BaseDashboard from './BaseDashboard'
import ModuleProfitAgregated from './modules/ModuleProfitAgregated'

// routes
export const GET_PROFIT_AGREGATED = 'GET_PROFIT_AGREGATED'

export default class Dashboard3_Finanse extends BaseDashboard {

  title = 'Finanse'

  modules = [
    { id: 'reset' },
    { id: 'profitAgregated', component: ModuleProfitAgregated, route: GET_PROFIT_AGREGATED }
  ]

  moduleStylesDefault = {
    reset: 'left: 0; top: 0; width: 32px; height: 32px',
    profitAgregated: 'left: 10px; top: 40px; width: 900px; height: 600px',
  }

}
