import config from '../config'
import { userLogout } from 'react-admin'

const { wsUrl } = config

let webSocket = null
let webSocketReconnectTimeout

function toggleWsStatus (active) {
  document.getElementById('ws-off-caption').style.display = !active ? 'block' : 'none'
}

export function wsConnect () {
  const loginToken = sessionStorage.getItem('loginToken')
  if (!loginToken) {
    console.error('Unexpected case - loginToken doesn\'t exist')
    return
  }

  webSocket = new WebSocket(`${wsUrl}?wsID=${loginToken}`)

  webSocket.onopen = () => {
    console.log('WebSocked connection opened')
    toggleWsStatus(true)
  }

  webSocket.onclose = () => {
    console.log('WebSocket closed. Reconnect after 3s')
    webSocket = null
    toggleWsStatus(false)

    webSocketReconnectTimeout = setTimeout(() => {
      wsConnect()
    }, 3000)
  }

  webSocket.onmessage = e => {
    try {
      const data = JSON.parse(e.data)
      onWsMsg(data)
    }
    catch (e) {
      throw Error('Can\'t parse WebSocket message data')
    }
  }
}

export function wsClose () {
  // console.log('wsClose')
  clearTimeout(webSocketReconnectTimeout)
  if (webSocket) {
    webSocket.onclose = null
    webSocket.close()
    webSocket = null
  }
}

async function onWsMsg ({ type, data }) {
  console.log(`onWsMsg type: ${type}, data: ${JSON.stringify(data)}`)

  if (type === 'order' && data === 'logout') {
    window.raDispatch(userLogout('login'))
  }

  if (type === 'startStream') {
    const { streamID, streamURL } = data

    // window.ssConfig.streamURL = 'https://dev-sio.quark.house/'
    window.ssConfig.streamURL = streamURL

    window.ssConfig.onRemoteStream = function (media) {
      // console.log('onRemoteStream')
      const video = media.video
      const videoContainer = document.getElementById('stream-video-container')
      videoContainer.innerHTML = ''
      videoContainer.append(video)
    }

    window.ssComponent_onStreamStart(streamID)

    window.screenSharingConnectToStream(streamID)
  }

  if (type === 'stopStream') {
    const { streamID } = data
    window.ssComponent_onStreamStop(streamID)
  }
}
