import React, { Component } from 'react'
import { GridOn as ResetIcon } from '@material-ui/icons'

const $ = window.$

export default class BaseDashboardNew extends Component {

  title = 'Dashboard'

  modules = []
  moduleStylesDefault = {}

  windowsZIndex = 100

  componentDidMount () {
    // Hack to set page Title
    setTimeout(() => {
      const pageTitle = document.getElementById('react-admin-title')
      if (pageTitle) {
        pageTitle.innerText = this.title
      }
    })

    // Drag-behaviour for module wrappers (windows)
    this.modules.forEach(module => {
      // Create window Element
      const el = document.getElementById('window-' + module.id)
      const style = this.loadWindowStyle(module.id)
      el.style = style ? style : this.moduleStylesDefault[module.id]

      el.addEventListener('mousedown', e => {
        e.currentTarget.style.zIndex = ++this.windowsZIndex
        this.saveWindowStyle(module.id, el)
      })

      if (module.id !== 'reset') {
        $(el).resizable({
          stop: () => {
            this.saveWindowStyle(module.id, el)
          }
        })
      }

      $(el).draggable({
        handle: module.id === 'reset' ? '.module' : '.module-header',
        // containment: 'parent', // Uncomment if wanna drag modules within dashboard only (not within entire page)
        stop: () => {
          this.saveWindowStyle(module.id, el)
        }
      })
    })
  }

  render () {
    const moduleReset = (
      <div className={'module'}>
        <i title={'Reset windows'} onClick={() => this.resetWindowsStyles()}>
          <ResetIcon/>
        </i>
      </div>
    )

    const windows = this.modules.map(module => {
      const ModuleComponent = module.component
      const moduleElement = module.id === 'reset'
        ? moduleReset
        : <ModuleComponent id={module.id} route={module.route}/>

      return (
        <div key={module.id} id={'window-' + module.id} className={'window'}>
          {moduleElement}
        </div>
      )
    })

    return (
      <div className={'dashboard'}>
        {windows}
      </div>
    )
  }

  localStorageStyleKey (id) {
    return `dashboard-${this.title}.window-${id}.style-v2`
  }

  saveWindowStyle (id, el) {
    localStorage.setItem(this.localStorageStyleKey(id), el.getAttribute('style'))
  }

  loadWindowStyle (id) {
    return localStorage.getItem(this.localStorageStyleKey(id))
  }

  resetWindowsStyles () {
    this.modules.forEach(({ id }) => {
      localStorage.removeItem(this.localStorageStyleKey(id))
      document.getElementById(`window-${id}`).style = this.moduleStylesDefault[id]
    })
  }
}
