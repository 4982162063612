import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: 'https://9a3da64945b045359770a6b9916327a8@o302895.ingest.sentry.io/5419188',
  environment: 'dev'
})

ReactDOM.render(<App />, document.getElementById('root'));
