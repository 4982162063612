const styles = (theme) => ({
  Input: {
    position: 'relative',
    width: '100%',
    height: '30px',

    '& input': {
      width: '100%',
      height: '100%',
      padding: '0 15px',
      color: 'rgb(80, 120, 160)',
      fontSize: ' 14px',
      fontWeight: ' 400',
      border: '1px solid #DBE2E8',
      borderRadius: ' 5px',
      outline: 'none',
      transition: 'borderColor 0.3s ease',

      '&:focus': {
        borderColor: '#02B3E4',
      },

      '&::placeholder': {
        color: 'rgba(80, 120, 160, 0.5)'
      },

      '&.error': {
        borderColor: '#C00034'
      },

      '&:disabled': {
        opacity: '0.6'
      }
    },
    '& alert': {
      top: '2px',
      right: '20px',
      position: 'absolute',
      color: '#C00034',
      fontSize: '10px',
      fontWeight: '300',
    }
  },
  alert: {
    top: '5px',
    right: '10px',
    position: 'absolute',
    color: '#C00034',
    fontSize: '12px',
    fontWeight: '300',
  }
})

export default styles
