const styles = (theme) => ({
    UserUpdateModal: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    
    'inputWrapper': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '6px',

        '& span': {
            width: '30%',
            textTransform: 'capitalize'
        },

        '& Input': {
            width: '70%',
            '& input': {
                borderRadius: '60px',
                background: 'transparent',
                color: '#fff6d2',
                '&:focus, &::placeholder': {
                    bordeColor:' #fff6d2',
                    color:' #fff6d2',
                }
            }
        },
        '& label': {
            width: '40%',
        }
    },
    'mainError': {
        color: 'red',
        fontSize: '12px',
        position: 'absolute'
    },
});

export default styles;
