import BaseDashboard from './BaseDashboard'
import ModuleTransferForm from './modules/ModuleTransferForm'
import ModuleManualOrders from './modules/ModuleManualOrders'
import ModuleDownloadTransfers from './modules/ModuleDownloadTransfers'
import ModuleSimpleTable from './modules/ModuleSimpleTable'
import { GET_ACTIONS, GET_CRYPTO_STATUS } from './Dashboard1'
import ModuleDistortionSetup from './modules/ModuleDistortionSetup'
import ModuleTradeInterface from './modules/ModuleTradeInterface'
import ModuleActions from './modules/ModuleActions'

// routes
export const GET_TRANSFER_OPTIONS = 'GET_TRANSFER_OPTIONS'
export const POST_TRANSFER = 'POST_TRANSFER'
export const GET_MANUAL_ORDERS = 'GET_MANUAL_ORDERS'
export const POST_MANUAL_ORDER = 'POST_MANUAL_ORDER'
export const GET_SET_OF_TRANSFERS = 'GET_SET_OF_TRANSFERS'
export const GET_DISTORTION_SETUP = 'GET_DISTORTION_SETUP'
export const POST_DISTORTION_SETUP = 'POST_DISTORTION_SETUP'
export const GET_TRADE_INTERFACE = 'GET_TRADE_INTERFACE'
export const POST_TRADE_INTERFACE = 'POST_TRADE_INTERFACE'

export default class Dashboard4_Transfery extends BaseDashboard {

  title = 'Transfery'

  modules = [
    { id: 'reset' },
    { id: 'actions', component: ModuleActions, route: GET_ACTIONS },
    { id: 'cryptoStatus', component: ModuleSimpleTable, route: GET_CRYPTO_STATUS },
    { id: 'transferForm', component: ModuleTransferForm, route: GET_TRANSFER_OPTIONS },
    { id: 'manualOrders', component: ModuleManualOrders, route: GET_MANUAL_ORDERS },
    { id: 'downloadTransfers', component: ModuleDownloadTransfers, route: null },
    { id: 'distortionSetup', component: ModuleDistortionSetup, route: GET_DISTORTION_SETUP },
    { id: 'tradeInterface', component: ModuleTradeInterface, route: GET_TRADE_INTERFACE }
  ]

  moduleStylesDefault = {
    reset: 'left: 0; top: 0; width: 32px; height: 32px',
    actions: 'left: 10px; top: 40px;  width: 170px; height: 220px',
    cryptoStatus: 'left: 190px; top: 10px;  width: 170px; height: 140px',
    transferForm: 'left: 10px; top: 270px; width: 350px; height: 200px',
    manualOrders: 'left: 10px; top: 480px; width: 350px; height: 380px',
    downloadTransfers: 'left: 190px; top: 160px; width: 170px; height: 100px',
    distortionSetup: 'left: 370px; top: 10px; width: 650px; height: 250px',
    tradeInterface: 'left: 370px; top: 270px; width: 800px; height: 400px',
  }

}
