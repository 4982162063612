import React from 'react'
import BaseModule from '../BaseModule'
import { Button } from 'react-admin'
import { GET_SET_OF_TRANSFERS } from '../Dashboard4_Transfery'

export default class ModuleDownloadTransfers extends BaseModule {
  downloadTransfers = () => {
    this.fetchData(GET_SET_OF_TRANSFERS)
      .then(({ filename, text }) => {
        console.log(GET_SET_OF_TRANSFERS, { filename, text })

        const textContent = 'data:text/plain;charset=utf-8,' + text
        const encodedUri = encodeURI(textContent)

        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', filename)
        link.style.display = 'none'
        document.body.appendChild(link) // Required for FF

        link.click()
        document.body.removeChild(link)
      })
  }

  renderBody (data) {
    return (
      <Button
        label={'Download'}
        className={'big-btn'}
        onClick={this.downloadTransfers}
      />
    )
  }
}
