import React, { Component } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core'
import { Button } from 'react-admin'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from '../users/UserUpdateModalStyles.js'
import Input from '../../other/Input'
import CustomSelect from '../../other/CustomSelect'

const percentTypeOpts = [
  { value: 'free', title: 'Free' },
  { value: 'fixed', title: 'Fixed' }
]

const PERCENT_MIN = 0.1

/**
 * Modal for crating/updating Cashier
 */
class CashierUpdateModal extends Component {
  static propTypes = {
    cashiersActions: PropTypes.object,
    cashier4edit: PropTypes.object,
    label: PropTypes.string
  }

  state = {
    isTypeSelectOpen: false,
    formData: null,
    error: {}
  }

  componentDidMount () {
    if (this.props.cashier4edit) {
      this.init(this.props.cashier4edit)
    }
  }

  // init for update or creation
  init (cashier4edit) {
    const data = cashier4edit.id === null ? null : cashier4edit
    const formData = {
      email: data ? data.email : '',
      nickname: data ? data.nickname : '',
      percentType: data ? data.percent.type : percentTypeOpts[0].value,
      percent: data ? data.percent.value.toString() : '',
      percentMin: data && data.percent.min !== undefined ? data.percent.min.toString() : '',
      percentMax: data && data.percent.max !== undefined ? data.percent.max.toString() : ''
    }

    // If creation - add is2FA field and hidden idFranchisee
    if (cashier4edit.id === null) {
      formData['is2FA'] = false
      formData['idFranchisee'] = cashier4edit.franchiseeId
    }

    this.setState({
      formData,
      error: {}
    })
  }

  handleToggleSelect = name => {
    this.setState(prevState => ({

      [name]: !prevState[name]
    }))
  }

  handleCloseClick = () => {
    this.props.cashiersActions.setCashier4edit(null)
  }

  checkPercent = (formData) => {
    const { percentType, percent, percentMin, percentMax } = formData
    if (percentType === 'fixed') {
      return PERCENT_MIN <= +percent && +percent <= 100 ? ''
        : `Wrong value. Please follow the rule: "${PERCENT_MIN} ⩽ Percent ⩽ 100"`
    } else {
      return (PERCENT_MIN <= +percentMin && +percentMin <= +percent && percent <= +percentMax && +percentMax <= 100) ? ''
        : `Wrong values in Percent fields. Please follow the rule:\n"${PERCENT_MIN} ⩽ Percent Min ⩽ Percent ⩽ Percent Max ⩽ 100"`
    }
  }

  handleSave = async (event) => {
    event.preventDefault()

    const { formData } = this.state
    const { cashier4edit, cashiersActions } = this.props

    const data = { ...formData }

    // Remove unused params if need
    if (formData.percentType === 'fixed') {
      delete data.percentMin
      delete data.percentMax
    }

    const error = {}
    for (let k in data) {
      if (data[k] === '') {
        error[k] = 'Required'
      }
    }
    if (Object.keys(error).length > 0) {
      this.setState({ error })
      return
    }

    const mainError = this.checkPercent(formData)
    if (mainError) {
      this.setState({
        error: { main: mainError }
      })
      return
    }

    if (cashier4edit.id === null) {
      cashiersActions.addCashier(data)
    } else {
      cashiersActions.updateCashier(cashier4edit.id, data)
    }

    this.handleCloseClick()
  }

  handleChange = (name, type, value) => {
    const { formData, error } = this.state

    this.setState({
      formData: {
        ...formData,
        [name]: value
      },
      error: {
        ...error,
        [name]: '',
        main: ''
      }
    })
  }

  handleTypeSelectChange = e => {
    const { value } = e.target

    const { formData, error } = this.state
    this.setState({
      formData: {
        ...formData,
        percentType: value
      },
      error: {
        ...error,
        percentType: '',
        percentMin: '',
        percentMax: '',
        main: ''
      }
    })
  }

  render () {
    const { classes, cashier4edit } = this.props
    const { formData, isTypeSelectOpen, error } = this.state

    if (!formData) {
      return null
    }

    const { email, nickname, is2FA, percentType, percent, percentMin, percentMax } = formData

    // This field only in creation mode
    const twoFAField = cashier4edit.id !== null ? null : (
      <div className={cn(classes.inputWrapper)}>
        <span className={styles.label}>2FA</span>
        <label style={{border: 'none', padding: '0'}}>
          <Checkbox
            checked={is2FA}
            name={'is2FA'}
            onChange={e => this.handleChange('is2FA', null, e.target.checked)}
            style={{ width: 'auto' }}
          />
          &nbsp;Yes
        </label>
      </div>
    )

    const percentMinField = percentType === 'fixed' ? null : (
      <div className={cn(classes.inputWrapper)}>
        <span className={styles.label}>percent min</span>
        <Input
          name='percentMin'
          type='number' step={0.1} min={PERCENT_MIN} max={100}
          placeholder='percentMin'
          value={percentMin}
          onChange={this.handleChange}
          errorText={error.percentMin}
          className={cn(classes.label)}
        />
      </div>
    )

    const percentField = (
      <div className={cn(classes.inputWrapper)}>
        <span className={styles.label}>percent</span>
        <Input
          name='percent'
          type='number' step={0.1} min={PERCENT_MIN} max={100}
          placeholder='percent'
          value={percent}
          onChange={this.handleChange}
          errorText={error.percent}
          className={cn(classes.label)}
        />
      </div>
    )

    const percentMaxField = percentType === 'fixed' ? null : (
      <div className={cn(classes.inputWrapper)}>
        <span className={styles.label}>percent max</span>
        <Input
          name='percentMax'
          type='number' step={0.1} min={PERCENT_MIN} max={100}
          placeholder='percentMax'
          value={percentMax}
          onChange={this.handleChange}
          errorText={error.percentMax}
          className={cn(classes.label)}
        />
      </div>
    )

    return (
      <Dialog
        fullWidth
        open={cashier4edit !== null}
        onClose={this.handleCloseClick}
      >
        <DialogTitle>
          {cashier4edit.id === null ? 'New cashier' : `Update ${cashier4edit.nickname || 'user'}`}
        </DialogTitle>
        <DialogContent>
          <div className={cn(classes.CashierUpdateModal)}>

            <div className={cn(classes.inputWrapper)}>
              <span className={cn(classes.label)}>email</span>
              <Input
                name='email'
                type='email'
                placeholder='email'
                value={email}
                onChange={this.handleChange}
                errorText={error.email}
                className={cn(classes.label)}
                disabled={cashier4edit.id !== null}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={cn(classes.label)}>nickname</span>
              <Input
                name='nickname'
                placeholder='nickname'
                value={nickname}
                onChange={this.handleChange}
                errorText={error.nickname}
                className={cn(classes.label)}
              />
            </div>

            {twoFAField}

            <div className={cn(classes.inputWrapper)}>
              <span className={cn(classes.label)}>Percentage type</span>
              <CustomSelect
                open={isTypeSelectOpen}
                options={percentTypeOpts}
                handleToggle={() => this.handleToggleSelect('isTypeSelectOpen')}
                handleChange={this.handleTypeSelectChange}
                value={percentType}
                name='percentType'
              />
            </div>

            {percentMinField}
            {percentField}
            {percentMaxField}

          </div>
          <div className={cn(classes.mainError)} style={{whiteSpace: 'pre'}}>{this.state.error.main}</div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleSave}
            label='Save'
            className={cn('ra-delete-button')}
            key='button'
          >
            <EditIcon/>
          </Button>
          <Button label='ra.action.cancel' onClick={this.handleCloseClick}/>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(CashierUpdateModal)


