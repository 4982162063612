import React, { Component, Fragment } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core'
import { Clear as IconCross, OpenInBrowser as IconMaximize } from '@material-ui/icons'
import IconMinimize from './IconMinimize'
import { STOP_STREAM } from '../admin/dashboard/Dashboard1'
import restApi from '../../api/restApi'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default class ScreenSharing extends Component {

  state = {
    streamId: null,
    userId: null,
    userName: null,
    showDialog: false
  }

  render () {
    const { streamId, showDialog, userName } = this.state

    const isConnected = streamId !== null
    const isInited = userName !== null

    const message = isConnected
      ? <span>You've got access to <b>{userName}</b></span>
      : <span>You're connecting to <b>{userName}</b></span>

    let btnStop = isInited ? (
      <div
        className={'btn-action btn-action_danger'}
        onClick={this.stopStream}
      >
        <IconCross/>
      </div>
    ) : null

    return (
      <Fragment>
        <div className={'screen-share-alert ' + (isInited ? 'screen-share-alert_visible' : '')}>
          {message}
          {btnStop}
          <div
            className={'btn-action'}
            onClick={this.showDialog}
          >
            <IconMaximize/>
          </div>
        </div>

        <Dialog
          id={'stream-dialog'}
          open={showDialog}
          TransitionComponent={Transition}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle style={{ padding: '12px 15px 8px', background: '#eee' }}>
            <span>{userName}</span>

            <Button
              className={'btn-minimize'}
              onClick={this.handleDialogClose}
            >
              <IconMinimize/>
            </Button>
          </DialogTitle>

          <DialogContent style={{ padding: '0' }}>
            <div style={{ padding: '20px', display: streamId ? 'none' : 'block' }}>
              Waiting for user...
            </div>
            <div id={'stream-video-container'} style={{ display: streamId ? 'block' : 'none' }}/>
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }

  handleDialogClose = () => {
    this.setState({ showDialog: false })
  }

  stopStream = () => {
    const { streamId } = this.state
    restApi(STOP_STREAM, '', { streamId })
      .then(() => {
        this.onStreamStop(streamId)
      })
  }

  showDialog = () => {
    this.setState({ showDialog: true })
  }

  /************************* For external access **************************/

  initStream ({ id, name }) {
    if (this.state.userId === id) {
      this.showDialog()
    } else {
      this.setState({
        streamId: null,
        userId: id,
        userName: name,
        showDialog: true
      })
    }

  }

  onStreamStart (streamId) {
    this.setState({
      streamId
    })
  }

  onStreamStop (streamId) {
    if (streamId === this.state.streamId) {
      this.setState({
        streamId: null,
        userId: null,
        userName: null,
        showDialog: false
      })
    }
  }

  componentDidMount () {
    // Hack to externally do actions with component state
    window.ssComponent_initStream = this.initStream.bind(this)
    window.ssComponent_onStreamStart = this.onStreamStart.bind(this)
    window.ssComponent_onStreamStop = this.onStreamStop.bind(this)
  }

}
