import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core'
import ActionBlock from '@material-ui/icons/LockOpen'
import { translate } from 'ra-core'
import classnames from 'classnames'
import IconUnlock from '@material-ui/icons/Lock'
import { blue, grey, indigo } from '@material-ui/core/colors'
import { Button } from 'react-admin'

const styles = theme => ({
  unblock: {
    color: indigo[900],
    background: blue[400],
    '&:hover': {
      color: 'white',
      background: blue[600],
    }
  },
  block: {
    color: grey[700],
    background: grey[400],
    '&:hover': {
      color: 'white',
      background: grey[600],
    }
  },
  root: {
    minWidth: 200,
    minHeight: 40,
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
  }
})

class BlockButton extends Component {
  handleClick = () => {
    this.props.onClick()
  }

  render () {
    const { classes = {}, isBlocked } = this.props
    const btnType = isBlocked ? 'block' : 'unblock'

    return (
      <Button
        onClick={this.handleClick}
        label={`${isBlocked ? 'Unblock' : 'Block'}`}
        className={classnames(classes[btnType], classes.root)}
        key='button'
      >
        {isBlocked ? <IconUnlock/> : <ActionBlock/>}
      </Button>
    )
  }
}

BlockButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.func]),
  resource: PropTypes.string,
  startUndoable: PropTypes.func,
  translate: PropTypes.func,
  undoable: PropTypes.bool,
  onClick: PropTypes.func,
  isBlocked: PropTypes.bool
}

BlockButton.defaultProps = {
  redirect: 'list',
  undoable: true,
  isBlocked: false
}

export default compose(
  translate,
  withStyles(styles)
)(BlockButton)
