import React from 'react'
import BaseModule from '../BaseModule'
import restApi from '../../../../api/restApi'
import { POST_BINANCE_FORM } from '../Dashboard8_BinanceLoans'
import Select from 'react-select'
import { selectStyles } from '../select-styles'

const varFieldNames = {
  exchange: 'Exchange',
  type: 'Type',
  loanCurrency: 'Loan Currency',
  collateralCurrency: 'Collateral Currency',
}

export default class ModuleBinanceForm extends BaseModule {
  // Imitate "standard" module data
  dataResolver = data => ({
    name: 'Loans form',
    data,
  })

  constructor (props) {
    super(props)
    this.state = {
      ...this.state,

      exchange: null,
      type: null,
      loanCurrency: null,
      collateralCurrency: null,
      amount: '',
      timeout: '',
    }
  }

  renderBody (data) {
    const { exchange, type, loanCurrency, collateralCurrency, amount } = this.state
    const isValid = exchange && type && loanCurrency && collateralCurrency && amount

    const getOpts = key => data[key].map(o => ({ value: o, label: o }))

    const getSelectValue = key => {
      if (this.state[key]) {
        return getOpts(key).find(o => o.value === this.state[key])
      } else {
        return null
      }
    }

    const setSelectValue = (key, value) => {
      this.setState({ [key]: value })
    }

    const selectFields = ['exchange', 'type', 'loanCurrency', 'collateralCurrency']
      .map(key => (
        <div key={key}>
          <div>{varFieldNames[key]}</div>
          <Select options={getOpts(key)}
                  value={getSelectValue(key)}
                  onChange={e => setSelectValue(key, e.value)}
                  styles={selectStyles}
          />
        </div>
      ))

    return (
      <div className={'mod-binance-form'}>
        {selectFields}

        <div>
          <div>Amount</div>
          <input type="text" style={{ width: '95%' }}
                 value={this.state.amount}
                 onChange={e => this.setState({ amount: e.target.value })}
          />
        </div>

        <div>
          <div>Timeout</div>
          <input type="text" style={{ width: '95%' }}
                 value={this.state.timeout}
                 onChange={e => this.setState({ timeout: e.target.value })}
          />
        </div>

        <div>
          <button type="button" className={'btn'} style={{ width: '100%', position: 'absolute', bottom: '0' }}
                  disabled={!isValid}
                  onClick={() => this.submit()}
          >Submit
          </button>
        </div>

      </div>
    )
  }

  submit () {
    const { exchange, type, loanCurrency, collateralCurrency, amount, timeout } = this.state

    const data = {
      exchange,
      type,
      loanCurrency,
      collateralCurrency,
      amount: +amount,
    }
    if (timeout.trim() !== '') {
      data.timeout = +timeout
    }
    restApi(POST_BINANCE_FORM, '', data)
      .then(() => {
        this.setState({
          exchange: null,
          type: null,
          loanCurrency: null,
          collateralCurrency: null,
          amount: '',
          timeout: '',
        })

        // Hack (reload another module)
        if (window.moduleBinanceActions) {
          window.moduleBinanceActions.reloadData()
        }
      })
  }
}
