import React from 'react'
import BaseModule from '../BaseModule'
import { INIT_STREAM } from '../Dashboard1'

export default class ModuleLogged extends BaseModule {

  renderBody (data) {
    const initStream = user => {
      this.fetchData(INIT_STREAM, { cashierId: user.id })
        .then(() => {
          window.ssComponent_initStream(user)
        })
    }

    const blocks = Object.entries(data).map(([type, users]) => {
      let list = <div>--</div>
      if (users.length > 0) {
        list = users.map((user, i) => (
          <div className={'logged-user'} key={i}>
            <span>#{user.id}</span>
            <span>{user.name}</span>
            {type === 'cashiers' ? <span className={'action-btn'}
                                         onClick={() => initStream(user)}>[connect]</span> : null}
          </div>
        ))
      }

      return (
        <div className={'block'} key={type}>
          <div className={'title'}>{type}</div>
          {list}
        </div>
      )
    })

    return (
      <div className={'mod-logged'}>
        {blocks}
      </div>
    )
  }

}
