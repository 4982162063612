import React from 'react'
import BaseModule from '../BaseModule'

const $ = window.$

export default class ModuleBalances extends BaseModule {

  balanceInited = false

  renderBody (data) {
    if (!this.balanceInited) {
      setTimeout(() => {
        $('.market-btn').click(() => {
          $(this).toggleClass('collapsed')
          $(this).next().toggle()
        })
      })
      this.balanceInited = true
    }

    const markets = Object.entries(data).map(([marketId, marketData]) => {
      return (
        <div key={marketId} className={'balance-market'}>
          <button type={'button'} className={'market-btn'}>
            <span className="icon-uncollapse">+</span>
            <span className="icon-collapse">-</span>
            <span style={{ paddingLeft: '0.25rem' }}>{marketId}</span>
          </button>
          <div>
            {this.renderMarket(marketData)}
          </div>
        </div>
      )
    })

    return (
      <div className={'mod-balances'}>
        {markets}
      </div>
    )
  }

  renderMarket (data) {
    const pairs = Object.entries(data).map(([coin, value]) => {
      return (
        <div className={'balance-row'} key={coin}>
          <span dangerouslySetInnerHTML={{ __html: coin }}/>
          <b dangerouslySetInnerHTML={{ __html: value }} style={{ float: 'right' }}/>
        </div>
      )
    })
    return <div>{pairs}</div>
  }

}
