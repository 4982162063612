import { put, select, takeEvery } from 'redux-saga/effects'
import * as ca from './cashiersActions'
import { getCashiersList, SET_DRAWER } from './cashiersActions'
import { showSuccessToastr } from '../../../toastr'
import { refreshView } from 'react-admin'

const successActions = [
  ca.ADD_TRANSACTION_SUCCESS,
  ca.ADD_CASHIER_SUCCESS,
  ca.UPDATE_CASHIER_SUCCESS,
  ca.CASHIER_ACTION_SUCCESS,
  ca.RESET_CASHIER_PASSWORD_SUCCESS,
  ca.CASHIER_SWITCH_2FA_SUCCESS
]

export default function * cashiersSaga () {
  yield [
    takeEvery(successActions, function * generator () {
      yield showSuccessToastr('The operation was successful')
    }),

    // On drawer close - refresh users list
    takeEvery(SET_DRAWER, function * generator ({ payload }) {
      if (!payload.state) {
        yield put(refreshView())
      }
    }),

    // Reload table on cashier create
    takeEvery(ca.ADD_CASHIER_SUCCESS, function * generator () {
      const { cashiersState, usersState } = yield select()
      yield put(getCashiersList(usersState.currentUserId, cashiersState.tablePagination))
    })
  ]
}
