import React from 'react'
import { Create, SelectInput, SimpleForm, TextInput } from 'react-admin'
import { FactoryValidation } from '../../../FactoryValidation'

function validateUserCreation (values) {
  const advancedRules = {
    name: ['required', { length_between: [2, 50] }],
    password: 'required'
  }

  return FactoryValidation(values, { ...advancedRules })
}

export default function UserCreate (props) {
  let langChoices = []
  const currenciesJSON = sessionStorage.getItem('currencies')
  if (currenciesJSON) {
    langChoices = JSON.parse(currenciesJSON)
      .map(x => ({ id: x, name: x.toUpperCase() }))
  } else {
    console.error(`sessionStorage.currencies is empty`)
  }

  return (
    <Create {...props} resource="masterFranchisee" title="Franchisee create">
      <SimpleForm validate={validateUserCreation} redirect="list">
        <TextInput source="name"/>
        <TextInput source="email"/>
        <TextInput source="address"/>
        <TextInput source="postcode"/>
        <TextInput source="city"/>
        <TextInput source="NIP"/>
        <TextInput source="percent"/>
        <SelectInput source="fiat" choices={langChoices}/>
        <TextInput source="limit24h"/>
        <TextInput source="accountNo" label="Bank account number"/>
      </SimpleForm>
    </Create>
  )
}
