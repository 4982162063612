import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function IconMinimize (props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 19h12v2H6z"/></svg>
    </SvgIcon>
  )
}
