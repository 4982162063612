const styles = (theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        background: 'url(https://source.unsplash.com/random/1600x900)',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    },
    card: {
        minWidth: 300,
        marginTop: '6em'
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center'
    },
    icon: {
        backgroundColor: theme.palette.secondary.main
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500]
    },
    form: {
        padding: '0 1em 1em 1em'
    },
    input: {
        marginTop: '1em'
    },
    actions: {
        padding: '0 1em 1em 1em'
    }
});

export default styles;