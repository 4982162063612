import React from 'react'
import { SvgIcon } from '@material-ui/core'

export default function IconT (props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><text x="4" y="21">T</text></svg>
    </SvgIcon>
  )
}
