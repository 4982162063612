import BaseModule from '../BaseModule'
import React from 'react'
import restApi from '../../../../api/restApi'
import { WALLET_OUTPUTS } from '../Dashboard9_Outputs'
import { Button } from 'react-admin'

export default class ModuleWalletOutputs extends BaseModule {

  constructor (props) {
    super(props)
    this.state = {
      ...this.state,
      selectedRecord: null,
    }
  }

  renderBody (data) {
    console.log('render', data)
    const { selectedRecord } = this.state

    // Collect data
    const wallets = Object.keys(data)
    const rows = []
    for (const [wallet, walletItem] of Object.entries(data)) {
      for (const [coin, coinItem] of Object.entries(walletItem)) {
        let row = rows.find(r => r.coin === coin)
        if (!row) {
          row = { coin }
          rows.push(row)
        }

        row[wallet] = coinItem
      }
    }

    const tableRows = rows.map(rowData => {
      const walletCells = wallets.map(wallet => this.renderTableCell(rowData, wallet))
      return (
        <tr key={rowData.coin}>
          <td>{rowData.coin}</td>
          {walletCells}
        </tr>
      )
    })

    const btnConsolidate = selectedRecord ? (
      <Button
        label={'Consolidate'}
        className={'big-btn'}
        onClick={this.handleConsolidate}
      />
    ) : null

    return (
      <div className={'vm-table'}>
        <table>
          <thead>
          <tr>
            <th></th>
            {wallets.map((w, i) => <th key={i}>{w}</th>)}
          </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
        <br/>
        {btnConsolidate}
      </div>
    )
  }

  renderTableCell (rowData, wallet) {
    const { selectedRecord } = this.state
    const { coin } = rowData

    const hasSelection = selectedRecord &&
      selectedRecord.wallet === wallet &&
      selectedRecord.coin === coin

    const records = rowData[wallet].map((amount, i) => {
      let className = 'wallet-row'
      if (hasSelection && selectedRecord.idx >= i) {
        className += ' wallet-row_selected'
      }

      return (
        <div
          key={i}
          className={className}
          onClick={() => this.setState({ selectedRecord: { idx: i, coin, wallet, amount } })}
        >
          {amount}
        </div>
      )
    })

    return (
      <td
        key={wallet}
        style={{ verticalAlign: 'top' }}
      >
        {records}
      </td>
    )
  }

  handleConsolidate = () => {
    const { coin, wallet, amount } = this.state.selectedRecord
    const params = { coin, wallet, amount }
    restApi(WALLET_OUTPUTS, '', params)
      .then(({ data }) => {
        this.setState({ selectedRecord: null })
        this.onDataLoad(data)
      })
      .catch(e => console.error('Wallet request error', e))
  }

}
