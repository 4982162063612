import { hideNotification, refreshView } from 'react-admin'
import { put, takeEvery } from 'redux-saga/effects'
import * as ua from './usersActions'
import { UPDATE_USER_SUCCESS, USER_ACTION_SUCCESS, USER_SWITCH_2FA_SUCCESS } from './usersActions'
import { showSuccessToastr } from '../../../toastr'

const successActions = [
  ua.UPDATE_USER_SUCCESS,
  ua.USER_ACTION_SUCCESS,
  ua.RESET_FRANCHISEE_PASSWORD_SUCCESS
]

export default function * usersSaga () {
  yield [
    // eslint-disable-next-line
    takeEvery(successActions, function * generator () {
      showSuccessToastr('The operation was successful')
    }),
    takeEvery([UPDATE_USER_SUCCESS, USER_ACTION_SUCCESS, USER_SWITCH_2FA_SUCCESS], function * generator () {
      yield put(refreshView())
    }),

    // this is hack just to remove react-admin notifications (cause we have toastr notifications)
    // It's not for users only, but for hole project
    takeEvery('RA/SHOW_NOTIFICATION', function * generator () {
      yield put(hideNotification());
    }),
  ]
}
