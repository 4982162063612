import React, { Component } from 'react'
import EditIcon from '@material-ui/icons/Edit'
import { Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core'
import { Button } from 'react-admin'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './UserUpdateModalStyles.js'
import Input from '../../other/Input'

class UserUpdateModal extends Component {
  static propTypes = {
    updateUser: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    updateModalState: PropTypes.func.isRequired,
    record: PropTypes.object,
    label: PropTypes.string
  }

  static defaultProps = {
    record: {},
  }

  state = {
    isOpen: this.props.isOpen,
    id: '',
    formData: {
      NIP: '',
      address: '',
      email: '',
      fiat: '',
      limit24h: '',
      percent: '',
      accountNo: '',
      name: '',
      postcode: '',
      city: ''
    },
    error: {
      NIP: '',
      address: '',
      email: '',
      fiat: '',
      limit24h: '',
      percent: '',
      accountNo: '',
      name: '',
      postcode: '',
      city: ''
    }
  }

  componentWillReceiveProps (nextProps) {
    const { record } = nextProps

    if (Object.keys(nextProps.record).length) {
      this.setState({
        isOpen: nextProps.isOpen,
        id: record.id,
        formData: {
          NIP: record.NIP || '',
          address: record.address,
          email: record.email,
          fiat: record.fiat,
          limit24h: record.limit24h,
          percent: record.percent,
          name: record.name,
          postcode: record.postcode,
          accountNo: record.bankAccounts[record.fiat] || '',
          city: record.city
        }
      })
    }
  }

  handleCloseClick = () => {
    this.props.updateModalState('', false)
  }

  handleUpdate = async (event) => {
    event.preventDefault()
    try {
      const { id, formData } = this.state
      await this.props.updateUser(id, formData)
    } catch (e) {
      console.warn('fail update user', e)
    }

    this.handleCloseClick()
  }

  handleChange = (_, type, value) => {
    const { formData, error } = this.state

    this.setState({
      formData: {
        ...formData,
        [type]: value
      },
      error: {
        ...error,
        [type]: ''
      }
    })
  }

  render () {
    const { NIP, address, email, fiat, name, postcode, city, percent, limit24h, accountNo } = this.state.formData
    const { classes, isOpen } = this.props
    const { error } = this.state

    return (
      <Dialog
        fullWidth
        open={isOpen}
        onClose={this.handleCloseClick}
      >
        <DialogTitle>
          {`Update ${name || 'user'}`}
        </DialogTitle>
        <DialogContent>
          <div className={cn(classes.UserUpdateModal)}>
            <div className={cn(classes.inputWrapper)}>
              <span className={styles.label}>name</span>
              <Input
                type='name'
                placeholder='name'
                value={name}
                onChange={this.handleChange}
                errorText={error.name}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={styles.label}>email</span>
              <Input
                type='email'
                placeholder='email'
                value={email}
                disabled={true}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={styles.label}>address</span>
              <Input
                type='address'
                placeholder='address'
                value={address}
                onChange={this.handleChange}
                errorText={error.address}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={styles.label}>postcode</span>
              <Input
                type='postcode'
                placeholder='postcode'
                value={postcode}
                onChange={this.handleChange}
                errorText={error.postcode}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={styles.label}>city</span>
              <Input
                type='city'
                placeholder='city'
                value={city}
                onChange={this.handleChange}
                errorText={error.city}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={cn(classes.label)}>NIP</span>
              <Input
                type='NIP'
                placeholder='NIP'
                value={NIP}
                onChange={this.handleChange}
                errorText={error.NIP}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={styles.label}>percent</span>
              <Input
                type='percent'
                placeholder='percent'
                value={percent}
                onChange={this.handleChange}
                errorText={error.percent}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={styles.label}>fiat</span>
              <Input
                type='text'
                placeholder='fiat'
                value={fiat}
                disabled={true}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={styles.label}>Limit24h</span>
              <Input
                type='limit24h'
                placeholder='limit24h'
                value={limit24h}
                onChange={this.handleChange}
                errorText={error.limit24h}
                className={cn(classes.label)}
              />
            </div>
            <div className={cn(classes.inputWrapper)}>
              <span className={styles.label}>Bank number</span>
              <Input
                type='accountNo'
                placeholder='Bank account number'
                value={accountNo}
                onChange={this.handleChange}
                errorText={error.accountNo}
                className={cn(classes.label)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.handleUpdate}
            label='Save'
            className={cn('ra-delete-button')}
            key='button'
          >
            <EditIcon/>
          </Button>
          <Button label='ra.action.cancel' onClick={this.handleCloseClick}/>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(UserUpdateModal)


