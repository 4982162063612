export const normalizeData = (oldState, newData) => {
  let currentList = oldState
  const initObject = { paid: '0', package: '0' }

  if (currentList.length) {
    if (isUniqArray(currentList, newData.id)) {
      currentList.push({ ...initObject, ...newData })
    } else {
      currentList = currentList.map(item => {
        return item.id === newData.id ? { ...item, ...newData } : item
      })
    }
  } else {
    currentList.push({ ...initObject, ...newData })
  }

  return checkEmptyItems(currentList)
}

const checkEmptyItems = (array) => array.filter(el => el.paid !== 0 && el.package !== 0)

export const isUniqArray = (array, id) => {
  return !Boolean(array.find(item => item.id === id))
}

export const generateFileToDownload = (data) => {
  const a = document.createElement('a')

  a.href = URL.createObjectURL(new Blob([data], { type: 'text/txt' }))
  a.download = 'file.txt'

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
