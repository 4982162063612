import React, { Component } from 'react'
import cn from 'classnames'
import { Button } from 'react-admin'
import { IconButton, Paper, TablePagination, Typography, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'

import CustomTable from '../../other/CustomTable.jsx'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import CashierUpdateModal from './CashierUpdateModal'
import ErrorBoundary from '../../../ErrorBoundary'

const styles = theme => ({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    justifyContent: 'space-between',
    margin: '0 1em 1em'
  },
  button: {
    margin: '1em',
    backgroundColor: 'rgba(63, 81, 181, 0.15)'
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 400
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30
    }
  },
  inlineField: {
    display: 'inline-block',
    width: '50%'
  },
  emptyTableLabel: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    justifyContent: 'center',
    height: '400px',
    fontSize: '20px',
  }
})

class DrawerList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    dataList: PropTypes.array.isRequired,
    tableTotalItems: PropTypes.number,
    openTransactionsAddModal: PropTypes.func,
    cashiersActions: PropTypes.object,
    loadFunc: PropTypes.func.isRequired,
    franchiseeId: PropTypes.string.isRequired,
    cashier4edit: PropTypes.object,
    tablePagination: PropTypes.object
  }

  onTableSort = async (field) => {
    const { tablesSortParams, type, cashiersActions } = this.props
    const sortParams = tablesSortParams[type]

    const newSortParams = {
      orderBy: field,
      order: sortParams && sortParams.orderBy === field && sortParams.order === 'ASC' ? 'DESC' : 'ASC'
    }

    // On sort change need set pagination to default
    const newPagination = { page: 0, size: 10 }
    cashiersActions.setTablePagination(newPagination)
    cashiersActions.setTableSortParams(type, newSortParams)
    this.reloadData(newPagination, newSortParams)
  }

  handleChangePage = async (event, page) => {
    const pagination = { ...this.props.tablePagination, page }
    this.props.cashiersActions.setTablePagination(pagination)
    this.reloadData(pagination, null)
  }

  handleChangeRowsPerPage = async e => {
    const pagination = { ...this.props.tablePagination, size: e.target.value }
    this.props.cashiersActions.setTablePagination(pagination)
    this.reloadData(pagination, null)
  }

  reloadData (newPagination, newSortParams) {
    const { franchiseeId, loadFunc, type, tablePagination, tablesSortParams } = this.props

    loadFunc(
      franchiseeId,
      newPagination || tablePagination,
      newSortParams || tablesSortParams[type]
    )
  }

  openCashierAddModal = () => {
    this.props.cashiersActions.setCashier4edit({ id: null, franchiseeId: this.props.franchiseeId })
  }

  render () {
    const {
      classes, dataList, tableTotalItems, onCancel, type, openTransactionsAddModal,
      cashiersActions, cashier4edit, label, tablePagination, tablesSortParams
    } = this.props

    return (
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography variant="title">"{label}" {type} list</Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon/>
          </IconButton>
        </div>

        <Button label={type === 'transactions' ? 'ADD TRANSACTION' : 'ADD CASHIER'}
                onClick={type === 'transactions' ? openTransactionsAddModal : this.openCashierAddModal}
                className={cn('ra-delete-button', classes.button)}
        >
          <AddIcon/>
        </Button>

        {tableTotalItems > 0 ?
          <Paper className={classes.root}>
            <ErrorBoundary>
              <CustomTable
                data={dataList}
                type={type}
                sortParams={tablesSortParams[type]}
                requestSort={this.onTableSort}
                cashiersActions={cashiersActions}
              />
              <TablePagination
                component="div"
                count={tableTotalItems}
                rowsPerPageOptions={[10, 25, 100, 10000]}
                rowsPerPage={tablePagination.size}
                page={tablePagination.page}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </ErrorBoundary>
          </Paper>
          :
          <div className={classes.emptyTableLabel}>There are no {type} for this Franchisee. Please add a {type} with the
            button above.</div>
        }

        <CashierUpdateModal
          cashier4edit={cashier4edit}
          cashiersActions={cashiersActions}
        />
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    tablePagination: state.cashiersState.tablePagination,
    tablesSortParams: state.cashiersState.tablesSortParams,
  }
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(DrawerList)

