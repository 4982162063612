import React, { Component } from 'react'
import restApi from '../../../api/restApi'
import Select from 'react-select'
import { selectStyles } from './select-styles'

// routes
export const VIRTUAL_MONEY = 'GET_VIRTUAL_MONEY'

export default class Dashboard7_VirtualMoney extends Component {

  title = 'Virtual money'

  route = VIRTUAL_MONEY

  state = {
    franchees: [],
    data: null,

    collapseSubListFor: [], // List of franchee names

    editingNoteId: null,

    formValue: '',
    formSelectedOpt: null,
  }

  // form = {
  //   id: null,
  //   value: 0,
  // }

  refNoteTextarea = null

  componentDidMount () {
    this.loadData()
  }

  loadData () {
    restApi(VIRTUAL_MONEY, '', {})
      .then(({ data }) => {
        const { virtualMoneyList, franchees } = data
        this.setState({ franchees, data: virtualMoneyList })
      })
      .catch(e => console.error('Virtual money request error', e))
  }

  render () {
    setTimeout(() => {
      const pageTitle = document.getElementById('react-admin-title')
      if (pageTitle) {
        pageTitle.innerText = this.title
      }
    })

    const { data } = this.state

    if (!data) {
      return <div>...</div>
    }

    const form = this.renderForm()
    const table = this.renderTable(data)
    return (
      <div className={'dashboard dashboard-vm'}>
        {form}
        {table}
      </div>
    )
  }

  renderForm () {
    const { franchees, formSelectedOpt, formValue } = this.state

    const francheesOpts = franchees.map(item => ({
      value: item.id,
      label: item.nickname,
    }))

    return (
      <div className={'vm-form'}>
        <form>
          <div>
            <div>Franchee</div>
            <Select options={francheesOpts}
                    value={formSelectedOpt}
                    onChange={opt => this.setState({ formSelectedOpt: opt })}
                    styles={selectStyles}
            />
          </div>

          <div style={{ marginTop: '10px' }}>
            <div>Value</div>
            <div>
              <input type="text"
                     style={{ width: '95%' }}
                     value={formValue}
                     onChange={e => this.setState({ formValue: e.target.value })}
              />
            </div>
          </div>
        </form>

        <div style={{ marginTop: '10px' }}>
          <button type="button" className={'btn'}
                  onClick={() => this.sendForm()}
                  disabled={!this.isFormValid()}
          >Send
          </button>
        </div>
      </div>
    )
  }

  renderTable (data) {
    const rows = data.map((item, i) => {
      const { franchee, total, VirtualMoneyList } = item
      const list = this.renderVirtualMoneyList(franchee, VirtualMoneyList)

      return (
        <tr key={i.toString() + franchee}>
          <td>{franchee}</td>
          <td>{total}</td>
          <td>
            {list}
          </td>
        </tr>
      )
    })

    return (
      <div className={'vm-table'}>
        <table>
          <thead>
          <tr>
            <th>Franchee</th>
            <th>Total</th>
            <th>SubList</th>
          </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    )
  }

  renderVirtualMoneyList (name, data) {
    const { collapseSubListFor, editingNoteId } = this.state
    const showInnerTable = !collapseSubListFor.includes(name)

    if (editingNoteId !== null) {
      setTimeout(() => {
        this.refNoteTextarea.focus()
        this.refNoteTextarea.selectionStart = this.refNoteTextarea.value.length
      })
    }

    let table = null
    if (showInnerTable) {
      const rows = data.map((item, i) => {
        const buttons = item.buttons.map(action => (
          <button
            key={action}
            style={{ display: 'inline-block', marginRight: '0.5rem' }}
            className={'btn'}
            onClick={() => this.sendAction(item.id, action)}
          >{action}
          </button>
        ))

        return (
          <tr key={i.toString() + item.id}>
            <td>{item.stampInsert}</td>
            <td>{item.value}</td>
            <td>{item.currency.toUpperCase()}</td>
            <td>
              <input
                type={'checkbox'}
                checked={+item.permanent}
                onChange={() => this.toggleIsPermanent(item)}
              />
            </td>
            <td>{item.state}</td>
            <td className={'editable-text'} onClick={e => this.handleNoteClick(e, item.id)}>
              {
                item.id === editingNoteId ? (
                  <textarea
                    ref={(el) => this.refNoteTextarea = el}
                    onBlur={() => this.completeNoteEdit()}
                    cols={50}
                    rows={4}
                    defaultValue={item.notes}
                  ></textarea>
                ) : (
                  <span style={{ display: 'inline-block', maxWidth: '250px' }}>{item.notes}</span>
                )
              }

            </td>
            <td>{buttons}</td>
          </tr>
        )
      })

      table = (
        <table className={'table-stripped'}>
          <thead>
          <tr>
            <th>Created</th>
            <th>Value</th>
            <th>Currency</th>
            <th>Permanent</th>
            <th>State</th>
            <th>Notes</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      )
    }

    return (
      <div>
        <button
          className={'btn'}
          onClick={() => this.toggleInnerTable(name)}
        >{showInnerTable ? '-' : '+'}
        </button>

        {table}
      </div>
    )
  }

  toggleInnerTable (name) {
    const { collapseSubListFor } = this.state
    const index = collapseSubListFor.indexOf(name)
    if (index === -1) {
      collapseSubListFor.push(name)
    } else {
      collapseSubListFor.splice(index, 1)
    }
    this.setState({ collapseSubListFor })
  }

  toggleIsPermanent (item) {
    this.sendAction(item.id, +item.permanent ? 'NOpermanent' : 'permanent')
  }

  handleNoteClick (e, id) {
    // If double (or triple) click - toggle editing
    if (e.detail > 1) {
      this.toggleNoteEditing(id)
    }
  }

  toggleNoteEditing (id) {
    this.setState({ editingNoteId: id })
  }

  completeNoteEdit () {
    const params = { updateNote: this.state.editingNoteId, note: this.refNoteTextarea.value }
    restApi(VIRTUAL_MONEY, '', params)
      .then(({ data }) => {
        const { virtualMoneyList, franchees } = data
        this.setState({ franchees, data: virtualMoneyList })
        // this.forceUpdate() // For rerender (in case of data not updated)
      })
      .catch(e => console.error('Virtual money request error', e))

    this.setState({ editingNoteId: null })
  }

  sendAction (id, action) {
    restApi(VIRTUAL_MONEY, '', { [action]: id })
      .then(({ data }) => {
        const { virtualMoneyList, franchees } = data
        this.setState({ franchees, data: virtualMoneyList })

        this.forceUpdate() // For rerender (in case of data not updated)
      })
      .catch(e => console.error('Virtual money request error', e))
  }

  isFormValid () {
    const { formSelectedOpt, formValue } = this.state

    return !!formSelectedOpt && !!+formValue
  }

  sendForm () {
    const { formSelectedOpt, formValue } = this.state

    const params = {
      newVM: formSelectedOpt.value,
      value: formValue,
    }
    restApi(VIRTUAL_MONEY, '', params)
      .then(({ data }) => {
        const { virtualMoneyList, franchees } = data
        this.setState({
          formSelectedOpt: null,
          formValue: '',
          franchees,
          data: virtualMoneyList,
        })
      })
      .catch(e => console.error('Virtual money request error', e))
  }
}
