import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core'
import { translate } from 'ra-core'
import classnames from 'classnames'
import DoneIcon from '@material-ui/icons/Done'
import { green, grey } from '@material-ui/core/colors'
import { Button } from 'react-admin'

const styles = theme => ({
  root: {
    minWidth: 200,
    background: green[200],
    marginLeft: 40,
    minHeight: 40,
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
  },
  disabled: {
    cursor: 'not-allowed',
    background: grey[700],
    color: 'white',

    '&:hover': {
      background: grey[700],
      color: 'white',
    }
  }
})

class SubmitButton extends Component {
  handleClick = () => {
    if (!this.props.isDisabled) this.props.onClick()
  }

  render () {
    const { classes = {}, isDisabled } = this.props

    return (
      <Button
        onClick={this.handleClick}
        label={'Submit'}
        className={classnames(classes.root, isDisabled ? classes.disabled : '')}
        key='submit_button'
      >
        <DoneIcon/>
      </Button>

    )
  }
}

SubmitButton.propTypes = {
  classes: PropTypes.object,
  isDisabled: PropTypes.bool
}

export default compose(
  translate,
  withStyles(styles)
)(SubmitButton)
