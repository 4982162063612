import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, propTypes, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CircularProgress,
    createMuiTheme,
    MuiThemeProvider,
    TextField,
    withStyles
} from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'

import { Notification, translate, userLogin } from 'react-admin'

import { FactoryValidation } from '../../FactoryValidation'

import { lightTheme } from '../../themes'
import styles from './styles'

class Login extends Component {
    static propTypes = {
        ...propTypes,
        translate    : PropTypes.func.isRequired,
        userLogin    : PropTypes.func.isRequired,
        authProvider : PropTypes.func,
        classes      : PropTypes.object
    };

    login = auth =>
        this.props.userLogin(auth, this.props.location.state ? this.props.location.state.nextPathname : '/');
    renderInput({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) {
        return (
            <TextField
                error={!!(touched && error)}
                helperText={touched && error}
                {...inputProps}
                {...props}
                fullWidth
            />
        );
    }

    render() {
        const { classes, handleSubmit, isLoading, translate } = this.props;

        return (
            <div className={classes.main}>
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    <form onSubmit={handleSubmit(this.login)}>
                        <div className={classes.hint}>Sign in with your account</div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    autoFocus
                                    name='username'
                                    component={this.renderInput}
                                    label={translate('ra.auth.username')}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name='password'
                                    component={this.renderInput}
                                    label={translate('ra.auth.password')}
                                    type='password'
                                    disabled={isLoading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name='ga'
                                    component={this.renderInput}
                                    label='2FA code'
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant='raised'
                                type='submit'
                                color='primary'
                                disabled={isLoading}
                                className={classes.button}
                                fullWidth
                            >
                                {isLoading && <CircularProgress size={25} thickness={2} />}
                                {translate('ra.auth.sign_in')}
                            </Button>
                        </CardActions>
                    </form>
                </Card>
                <Notification />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoading : state.admin.loading > 0
    };
}
const enhance = compose(
    translate,
    reduxForm({
        form     : 'signIn',
        validate : values => {
            const rules = {
                username : [ 'required', 'email' ],
                password : 'required'
            };

            return FactoryValidation(values, rules);
        }
    }),
    connect(
        mapStateToProps,
        { userLogin }
    ),
    withStyles(styles)
);

const EnhancedLogin = enhance(Login);
const LoginWithTheme = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <EnhancedLogin {...props} />
    </MuiThemeProvider>
);

export default LoginWithTheme;
