const toastr = window.toastr

toastr.options = {
  showDuration: 200,
  hideDuration: 200,
  tapToDismiss: false
}

export function showSuccessToastr (text) {
  toastr.success(text, '', {
    timeOut: 2500, // How long the toast will display without user interaction
    extendedTimeOut: 5000 // How long the toast will display after a user hovers over it
  })
}

let errorsShown = 0

// Show overlay if any error toastr shown
function updateOverlay () {
  document.getElementById('toastr-overlay').style.display = errorsShown ? 'block' : 'none'
}

export function showErrorToastr (header, text) {
  errorsShown++
  updateOverlay()

  toastr.error(text, header, {
    closeButton: true,
    positionClass: 'toast-top-full-width',
    timeOut: 0,
    extendedTimeOut: 0,
    onHidden: () => {
      errorsShown--
      updateOverlay()
    }
  })
}


