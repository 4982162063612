import React, { Component } from 'react'
import restApi from '../../../api/restApi'
import { Button } from 'react-admin'

// routes
export const GET_RATES = 'GET_RATES'

const PARSE_ERROR_RELOAD_INTERVAL = 20
const DEFAULT_INTERVAL = 30

/**
 * This Dashboard's lifecycle is very similar to BaseModule. Some code just copy-pasted with some changes
 */
export default class Dashboard5_Rates extends Component {

  title = 'Rates'

  route = GET_RATES

  state = {
    moduleData: null,
    isDataError: false,
    percent: 5
  }

  fetchTimeoutId = null

  componentDidMount () {
    this.fetchData()
      .then(data => this.onDataLoad(data))
  }

  componentWillUnmount () {
    if (this.fetchTimeoutId) {
      clearTimeout(this.fetchTimeoutId)
    }
  }

  onDataLoad (data, once) {
    // reload data after interval
    if (!once) {
      const interval = data
        ? (data.interval ? data.interval : DEFAULT_INTERVAL)
        : PARSE_ERROR_RELOAD_INTERVAL

      this.fetchDataWithTimeout(interval)
        .then(data => this.onDataLoad(data))
    }

    this.setState({
      moduleData: data,
      isDataError: data === null
    })
  }

  // Fetch data
  // Return data or null in any case
  fetchData () {
    return new Promise(resolve => {
      const { percent } = this.state
      restApi(this.route, '', { percent })
        .then(({ data }) => resolve(data))
        .catch(() => resolve(null))
    })
  }

  fetchDataWithTimeout (timeoutSec) {
    return new Promise(resolve => {
      this.fetchTimeoutId = setTimeout(() => {
        this.fetchData().then(resolve)
      }, timeoutSec * 1000)
    })
  }

  render () {
    const { moduleData, isDataError } = this.state
    let title = moduleData && moduleData.name ? moduleData.name : this.title
    if (isDataError) {
      title += ' [DATA PARSE ERROR]'
    }

    let body = null
    try {
      const data = moduleData ? moduleData.data : null
      body = !this.route || data ? this.renderBody(data) : null
    } catch (e) {
      console.error(`Module "${this.title}" render error (maybe wrong server data) : `, e)
      title += ' [WRONG DATA]'
    }

    setTimeout(() => {
      const pageTitle = document.getElementById('react-admin-title')
      if (pageTitle) {
        pageTitle.innerText = title
      }
    })

    return (
      <div className={'dashboard dashboard-rates'}>
        {body}
      </div>
    )
  }

  /**************************** Render *******************************/

  updateHandler = () => {
    this.fetchData()
      .then(data => this.onDataLoad(data))
  }

  onPercentChange (e) {
    let newValue = +e.target.value
    if (!newValue) {
      newValue = 0
    }
    this.setState({ percent: Math.max(0, Math.min(100, newValue)) })
  }

  renderBody () {
    const { moduleData, percent } = this.state
    const table = this.renderTable(moduleData.data)
    return (
      <div>
        {table}
        <div className={'percent-form'}>
          Percent: &nbsp;
          <input type="text"
                 style={{ width: '60px' }}
                 value={percent}
                 onChange={e => this.onPercentChange(e)}
          />

          <Button
            label={'OK '}
            className={'big-btn'}
            onClick={this.updateHandler}
          />
        </div>
      </div>
    )
  }

  renderTable (data) {
    const rows = Object.entries(data)
      .map(([market, item]) => {
        const { weBuyFIAT, weSellFIAT, fullName } = item
        return (
          <tr key={market}>
            <td>{weBuyFIAT}</td>
            <td>{weSellFIAT}</td>
            <td>{market} / {fullName}</td>
          </tr>
        )
      })

    return (
      <div className={'rates-table'}>
        <table>
          <thead>
          <tr>
            <th>We Buy</th>
            <th>We Sell</th>
          </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    )
  }
}
