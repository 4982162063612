export const USER_ACTION = 'USER_ACTION'
export const USER_ACTION_SUCCESS = 'USER_ACTION_SUCCESS'
export const USER_ACTION_FAILURE = 'USER_ACTION_FAILURE'

export function userAction (id, action) {
  return ({
    type: USER_ACTION,
    payload: { action, id },
    meta: { resource: 'masterFranchisee', fetch: USER_ACTION }
  })
}

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export function updateUser (id, data) {
  return ({
    type: UPDATE_USER,
    payload: { data, id },
    meta: { resource: 'masterFranchisee', fetch: UPDATE_USER }
  })
}

export const SET_CURRENT_USER_DATA = 'SET_CURRENT_USER_DATA'

export function setCurrentUserData (id, name, fiat) {
  return ({
    type: SET_CURRENT_USER_DATA,
    payload: { id, name, fiat }
  })
}

export const RESET_FRANCHISEE_PASSWORD = 'RESET_FRANCHISEE_PASSWORD'
export const RESET_FRANCHISEE_PASSWORD_SUCCESS = 'RESET_FRANCHISEE_PASSWORD_SUCCESS'
export const RESET_FRANCHISEE_PASSWORD_FAILURE = 'RESET_FRANCHISEE_PASSWORD_FAILURE'

export function resetFranchiseePassword (id) {
  return ({
    type: RESET_FRANCHISEE_PASSWORD,
    payload: { id },
    meta: { resource: 'masterFranchisee', fetch: RESET_FRANCHISEE_PASSWORD }
  })
}

export const USER_SWITCH_2FA = 'USER_SWITCH_2FA'
export const USER_SWITCH_2FA_SUCCESS = 'USER_SWITCH_2FA_SUCCESS'
export const USER_SWITCH_2FA_FAILURE = 'USER_SWITCH_2FA_FAILURE'

export function userSwitch2FA (id, value) {
  return ({
    type: USER_SWITCH_2FA,
    payload: { id, value },
    meta: { resource: 'masterFranchisee', fetch: USER_SWITCH_2FA }
  })
}

export const SET_MODAL_STATE = 'SET_MODAL_STATE'

export function setModalState (type, state) {
  return ({
    type: SET_MODAL_STATE,
    payload: {
      type, state
    }
  })
}
