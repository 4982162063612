import React from 'react'
import BaseModule from '../BaseModule'

export default class ModuleSimpleTable extends BaseModule {

  renderBody (data) {
    return Object.entries(data).map(([key, value]) => (
      <div className={'data-row'} key={key}>
        <span dangerouslySetInnerHTML={{ __html: key }}/>
        <span dangerouslySetInnerHTML={{ __html: value }}/>
      </div>
    ))
  }
}
