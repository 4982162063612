

export const GET_CASHIER = 'GET_CASHIER'
export const GET_CASHIER_SUCCESS = 'GET_CASHIER_SUCCESS'
export const GET_CASHIER_FAILURE = 'GET_CASHIER_FAILURE'

export function getCashier (id) {
  return ({
    type: GET_CASHIER,
    payload: { id },
    meta: { resource: 'masterCashier', fetch: GET_CASHIER }
  })
}

export const SET_CASHIER_4_EDIT = 'SET_CASHIER_4_EDIT'

// To clear form or init Cashier creation
// cashier = null | { id: null }
export function setCashier4edit (cashier) {
  return ({
    type: SET_CASHIER_4_EDIT,
    payload: { cashier }
  })
}

export const GET_TRANSACTIONS_LIST = 'GET_TRANSACTIONS_LIST'
export const GET_TRANSACTIONS_LIST_SUCCESS = 'GET_TRANSACTIONS_LIST_SUCCESS'
export const GET_TRANSACTIONS_LIST_FAILURE = 'GET_TRANSACTIONS_LIST_FAILURE'

export function getTransactionsList (franchiseeId, pagination, sortParams) {
  return ({
    type: GET_TRANSACTIONS_LIST,
    payload: { franchiseeId, pagination, sortParams },
    meta: { resource: 'masterListFranchiseeTxs', fetch: GET_TRANSACTIONS_LIST }
  })
}

export const ADD_TRANSACTION = 'ADD_TRANSACTION'
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS'
export const ADD_TRANSACTION_FAILURE = 'ADD_TRANSACTION_FAILURE'

export function addTransaction (id, data) {
  return ({
    type: ADD_TRANSACTION,
    payload: { data, id },
    meta: { resource: 'masterAddTransaction', fetch: ADD_TRANSACTION }
  })
}

export const GET_CASHIERS_LIST = 'GET_CASHIERS_LIST'
export const GET_CASHIERS_LIST_SUCCESS = 'GET_CASHIERS_LIST_SUCCESS'
export const GET_CASHIERS_LIST_FAILURE = 'GET_CASHIERS_LIST_FAILURE'

export function getCashiersList (franchiseeId, pagination, sortParams) {
  return ({
    type: GET_CASHIERS_LIST,
    payload: { franchiseeId, pagination, sortParams },
    meta: { resource: 'masterListFranchiseeCashiers', fetch: GET_CASHIERS_LIST }
  })
}

export const CASHIER_ACTION = 'CASHIER_ACTION'
export const CASHIER_ACTION_SUCCESS = 'CASHIER_ACTION_SUCCESS'
export const CASHIER_ACTION_FAILURE = 'CASHIER_ACTION_FAILURE'

export function cashierAction (id, action) {
  return ({
    type: CASHIER_ACTION,
    payload: { action, id },
    meta: { resource: 'masterCashier', fetch: CASHIER_ACTION }
  })
}

export const ADD_CASHIER = 'ADD_CASHIER'
export const ADD_CASHIER_SUCCESS = 'ADD_CASHIER_SUCCESS'
export const ADD_CASHIER_FAILURE = 'ADD_CASHIER_FAILURE'

export function addCashier (data) {
  return ({
    type: ADD_CASHIER,
    payload: { data },
    meta: { resource: 'masterCashier', fetch: ADD_CASHIER }
  })
}

export const UPDATE_CASHIER = 'UPDATE_CASHIER'
export const UPDATE_CASHIER_SUCCESS = 'UPDATE_CASHIER_SUCCESS'
export const UPDATE_CASHIER_FAILURE = 'UPDATE_CASHIER_FAILURE'

export function updateCashier (id, data) {
  return ({
    type: UPDATE_CASHIER,
    payload: { id, data },
    meta: { resource: 'masterCashier', fetch: UPDATE_CASHIER }
  })
}

export const SET_DRAWER = 'SET_DRAWER'

export function setDrawerState (type, state) {
  return ({
    type: SET_DRAWER,
    payload: {
      type, state
    }
  })
}

export const SET_TABLE_PAGINATION = 'SET_TABLE_PAGINATION'

export function setTablePagination ({ page, size }) {
  return ({
    type: SET_TABLE_PAGINATION,
    payload: { page, size }
  })
}

export const SET_TABLE_SORT_PARAMS  = 'SET_TABLE_SORT_PARAMS';

export function setTableSortParams(type, sortParams) {
  return ({
    type    : SET_TABLE_SORT_PARAMS,
    payload : { type, sortParams }
  });
}

export const RESET_CASHIER_PASSWORD = 'RESET_CASHIER_PASSWORD'
export const RESET_CASHIER_PASSWORD_SUCCESS = 'RESET_CASHIER_PASSWORD_SUCCESS'
export const RESET_CASHIER_PASSWORD_FAILURE = 'RESET_CASHIER_PASSWORD_FAILURE'

export function resetCashierPassword (id) {
  return ({
    type: RESET_CASHIER_PASSWORD,
    payload: { id },
    meta: { resource: 'masterCashier', fetch: RESET_CASHIER_PASSWORD }
  })
}

export const CASHIER_SWITCH_2FA = 'CASHIER_SWITCH_2FA'
export const CASHIER_SWITCH_2FA_SUCCESS = 'CASHIER_SWITCH_2FA_SUCCESS'
export const CASHIER_SWITCH_2FA_FAILURE = 'CASHIER_SWITCH_2FA_FAILURE'

export function cashierSwitch2FA (id, value) {
  return ({
    type: CASHIER_SWITCH_2FA,
    payload: { id, value },
    meta: { resource: 'masterCashier', fetch: CASHIER_SWITCH_2FA }
  })
}
