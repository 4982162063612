import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { FormControl, MenuItem, Select, withStyles } from '@material-ui/core'

import styles from './CustomSelectStyles.js'

class CustomSelect extends PureComponent {
    static propTypes = {
        value        : PropTypes.string,
        name         : PropTypes.string,
        options      : PropTypes.array,
        handleChange : PropTypes.func,
        handleToggle : PropTypes.func,
        className    : PropTypes.string,
        open         : PropTypes.bool
    }

    render() {
        const { options, classes, className, open, handleChange, handleToggle, value, name } = this.props;

        return (
            <div className={cn(classes.CustomSelect, classes[className])}>
                <FormControl style={{ width: '77%' }}>
                    <Select
                        open={open}
                        onClose={handleToggle}
                        onOpen={handleToggle}
                        value={value}
                        onChange={handleChange}
                        inputProps={{ name }}
                    >
                        {options.map(({ value, title }, i) => <MenuItem value={value} key={i}>{title}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
        );
    }
}

export default withStyles(styles)(CustomSelect);
