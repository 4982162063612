export const lightTheme = {
    palette : {
        secondary : {
            light        : '#5f5fc4',
            main         : '#283593',
            dark         : '#001064',
            contrastText : '#fff'
        }
    }
};
