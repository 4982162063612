import React, { Component } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core'
import { Button } from 'react-admin'
import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './UserUpdateModalStyles.js'

/**
 * Information about user (franchisee)
 */
class UserShowModal extends Component {
    static propTypes = {
        isOpen           : PropTypes.bool,
        updateModalState : PropTypes.func.isRequired,
        record           : PropTypes.object
    };

    static defaultProps = {
        record: {},
    };

    state = {
        isOpen: this.props.isOpen,
        NIP: '',
        address: '',
        email: '',
        fiat: '',
        limit24h: '',
        percent: '',
        name: '',
        postcode: '',
        bankAccounts: '',
        city: '',
        id: ''
    };

    componentWillReceiveProps(nextProps) {
        const { record } = nextProps;

        if (Object.keys(nextProps.record).length) {
            this.setState({
                isOpen: nextProps.isOpen,
                NIP: record.NIP,
                address: record.address,
                email: record.email,
                fiat: record.fiat,
                limit24h: record.limit24h,
                percent: record.percent,
                name: record.name,
                postcode: record.postcode,
                bankAccounts: record.bankAccounts,
                city: record.city,
                id: record.id
            });
        }
    }

    handleCloseClick = () => {
        this.props.updateModalState('editFranchisee', false);
    }

    render() {
        const { NIP, address, email, fiat, name, postcode, city, percent, limit24h, bankAccounts } = this.state;
        const { classes, isOpen  } = this.props;

        return (
            <Dialog
                fullWidth
                open={isOpen}
                onClose={this.handleCloseClick}
            >
                <DialogTitle>
                    {`Show info "${name || 'user'}"`}
                </DialogTitle>
                <DialogContent>
                    <div className={cn(classes.UserUpdateModal)}>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={styles.label}>
                                name:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {name}
                            </label>
                        </div>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={styles.label}>
                                email:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {email}
                            </label>
                        </div>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={styles.label}>
                                fiat:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {fiat}
                            </label>
                        </div>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={styles.label}>
                                address:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {address}
                            </label>
                        </div>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={styles.label}>
                                postcode:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {postcode}
                            </label>
                        </div>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={styles.label}>
                                city:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {city}
                            </label>
                        </div>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={cn(classes.label)}>
                                NIP:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {NIP}
                            </label>
                        </div>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={styles.label}>
                                percent:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {percent}
                            </label>
                        </div>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={styles.label}>
                                Limmit 24h:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {limit24h}
                            </label>
                        </div>
                        <div className={cn(classes.inputWrapper)}>
                            <span className={styles.label}>
                                Bank number:
                            </span>
                            <label className = {cn(classes.showInfoLabel)}>
                                {bankAccounts[fiat]}
                            </label>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button label='ra.action.cancel' onClick={this.handleCloseClick}></Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(UserShowModal);


