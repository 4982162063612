import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { List } from 'react-admin'
import { withStyles } from '@material-ui/core'

import * as withdrawsActions from './withdrawsActions'
import EditableDatagrid from '../../other/EditableDatagrid'

import CustomActionButton from './CustomActionButton'
import { normalizeData } from '../../utils/normalizeData'

function styles (theme) {
  return {}
}

/**
 * Withdraws list
 *
 * Actual behavior:
 *
 * If (state === 0) Server Not Blocked
 *   Can NOT check rows or submit
 *   Unlock table with button [BLOCK]
 *   (Actually we block server)
 *
 * If (state === 1) Server Blocked
 *   CAN check rows or submit
 *   Show notification
 *   Block table with button [UNBLOCK]
 */
class WithdrawsList extends Component {
  static propTypes = {
    withdrawsActions: PropTypes.object,
    serverBlocked: PropTypes.bool,
    isLoading: PropTypes.bool
  }

  state = {
    isLoading: false,
    withdrawsData: [],
    columns: [
      { key: 'id', name: 'ID', width: '5%' },
      { key: 'stampInsert', name: 'Timestamp of order', width: '25%' },
      { key: 'fiat', name: 'FIAT', width: '10%' },
      { key: 'value', name: 'VALUE', width: '10%' },
      { key: 'name', name: 'Name', width: '25%' },
      { key: 'package', name: 'Package', editable: false, width: '10%' },
      { key: 'paid', name: 'Paid', editable: false, width: '10%' },
    ]
  }

  handleBlockToggle = async () => {
    const { serverBlocked } = this.props
    const { setTableAction } = this.props.withdrawsActions
    const action = serverBlocked ? 'unblock' : 'block'

    setTableAction(action)
  }

  handleSubmit = () => {
    const { withdrawsData } = this.state
    this.props.withdrawsActions.submitWithdraws(withdrawsData)

    this.setState({
      withdrawsData: []
    })
  }

  handleChange = data => {
    const { withdrawsData } = this.state
    this.setState({ withdrawsData: normalizeData(withdrawsData, data) })
  }

  render () {
    const { columns } = this.state
    const { withdrawsActions, serverBlocked, ...propsRest } = this.props

    return (
      <div>
        <List
          title="Withdraws"
          {...propsRest}
          pagination={null}
          actions={
            <CustomActionButton
              {...propsRest}
              serverBlocked={serverBlocked}
              onBlockToggle={this.handleBlockToggle}
              onSubmit={this.handleSubmit}
            />
          }
          bulkActionButtons={false}
        >
          <EditableDatagrid
            columns={columns}
            onChange={this.handleChange}
          />
        </List>
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    withdrawsActions: bindActionCreators(withdrawsActions, dispatch)
  }
}

function mapStateToProps (state) {
  return {
    serverBlocked: state.withdrawsState.serverBlocked,
    isLoading: !!state.admin.loading
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ), withStyles(styles)
)(WithdrawsList)
