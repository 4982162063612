import { refreshView } from 'react-admin'
import { put, takeEvery } from 'redux-saga/effects'
import { SUBMIT_WITHDRAWS_SUCCESS, WITHDRAW_TABLE_ACTION_SUCCESS } from './withdrawsActions'
import { generateFileToDownload } from '../../utils/normalizeData'
import { showSuccessToastr } from '../../../toastr'

export default function * usersSaga () {
  yield [
    // eslint-disable-next-line
    takeEvery(WITHDRAW_TABLE_ACTION_SUCCESS, function * generator ({ requestPayload }) {
      const { action } = requestPayload
      let text = `Server ${action}ed successfully. `
      if (action === 'block') {
        text += 'Now you can submit.'
      }
      showSuccessToastr(text)
    }),
    takeEvery(SUBMIT_WITHDRAWS_SUCCESS, function * generator ({ payload }) {
      if (payload.text) {
        generateFileToDownload(payload.text)
      }

      // Refresh grid
      yield put(refreshView())
    })
  ]
}
