import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  BooleanInput,
  Button,
  CardActions,
  CreateButton,
  Datagrid,
  Filter,
  List,
  Pagination,
  TextField,
} from 'react-admin'

import { Drawer, Switch, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import CashiersIcon from '@material-ui/icons/AssignmentInd'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import IconLock from '@material-ui/icons/Lock'
import IconLockOpen from '@material-ui/icons/LockOpen'
import EditIcon from '@material-ui/icons/Edit'

import * as usersActions from './usersActions'
import * as cashiersActions from '../cashiers/cashiersActions'

import DrawerList from '../cashiers/DrawerList'
import UserUpdateModal from './UserUpdateModal'
import BalanceUpdateModal from './BalanceUpdateModal'
import UserShowModal from './UserShowModal'
import IconResetPassword from '../../other/IconResetPassword'
import ButtonWithConfirm from '../../other/ButtonWithConfirm'

function styles () {
  return {
    drawerPaper: {
      padding: '30px',
      width: '75vw',
      height: 'calc(100% - 60px)',
    },
  }
}

const USERS_PAGINATION_OPTS = [25, 100, 10000]
const CustomPagination = props => (
  <Pagination rowsPerPageOptions={USERS_PAGINATION_OPTS} {...props} />
)

class UsersList extends Component {

  static propTypes = {
    classes: PropTypes.object.isRequired,
    record: PropTypes.object,
    usersActions: PropTypes.object,
    cashiersActions: PropTypes.object,
    cashiers: PropTypes.array,
    transactions: PropTypes.array,
    tableTotalItems: PropTypes.number,
    admin: PropTypes.object,
    tablesSortParams: PropTypes.object,
    drawerType: PropTypes.string,
    drawerOpen: PropTypes.bool,
    modalOpen: PropTypes.bool,
    modalType: PropTypes.string,
    currentUserId: PropTypes.string,
    currentUserName: PropTypes.string,
  }

  static defaultProps = {
    record: {},
    admin: {},
  }

  state = {
    currentUser: {},
  }

  handleTableBtnClick = (type, record) => {
    const { setCurrentUserData } = this.props.usersActions
    setCurrentUserData(record.id, record.name, record.fiat)

    const { setTablePagination, getCashiersList, getTransactionsList } = this.props.cashiersActions
    const pagination = { page: 0, size: 10 } // default pagination
    setTablePagination(pagination)

    const { tablesSortParams } = this.props

    const loadFunc = type === 'cashiers' ? getCashiersList : getTransactionsList
    loadFunc(record.id, pagination, tablesSortParams[type])
  }

  handleDrawerClose = () => {
    this.props.cashiersActions.setDrawerState('', false)
  }

  showInfoModal = () => {
    this.props.usersActions.setModalState('showFranchisee', true)
  }

  openTransactionsAddModal = () => {
    this.props.usersActions.setModalState('editBalance', true)
  }

  render () {
    const {
      classes, drawerType, drawerOpen, modalType, modalOpen, currentUserId, currentUserName, sortParams,
      usersActions, cashiersActions, tableTotalItems, tablesSortParams, currentUserFiat,
      ...propsRest
    } = this.props

    const isOpenEditFranchisee = modalType === 'editFranchisee' && modalOpen
    const isOpenShowFranchisee = modalType === 'showFranchisee' && modalOpen
    const isOpenEditBalance = modalType === 'editBalance' && modalOpen

    const UserNameField = ({ record }) => (
      <div className="popover-wrapper">
        <div dangerouslySetInnerHTML={{ __html: record.name }}/>
        <div className="popover-content" style={{ whiteSpace: 'nowrap' }}>
          <div>
            <Button href={'mailto:' + record.email} label="Send Email"/>
          </div>
          <div>
            <Button onClick={this.showInfoModal} label="Get Info"/>
          </div>
        </div>
      </div>
    )

    // Field, that shows Popover with values detailing by months
    const FieldWithMonthsDetailing = ({ record, source }) => {
      const data = record[source]
      if (!data) {
        return <div></div>
      }

      const dataPerMonth = []
      const years = []
      Object.entries(data).forEach(([k, v]) => {
        const [y, m] = k.split('-')
        if (y && m) {
          dataPerMonth.push({ y, m, v })
          if (!years.includes(y)) {
            years.push(y)
          }
        }
      })

      const total = <div dangerouslySetInnerHTML={{ __html: data.total }}/>
      if (years.length === 0) {
        return total
      }

      const yearsRows = []
      years.forEach((y, i) => {
        let yearTotal = 0
        const monthsRows = []
        dataPerMonth.filter(item => item.y === y).forEach((item, i) => {
          yearTotal += item.v
          monthsRows.push((
            <div key={item.m} className={'detailing-row'}>
              <span>{item.m}:</span> <span>{item.v.toLocaleString()}</span>
            </div>
          ))
        })

        let dataClassName = 'detailing-row hover-blue'
        if (i % 2 === 1) dataClassName += ' detailing-row_even'

        yearsRows.push((
          <div className={'popover-wrapper2'} key={y}>
            <div className={dataClassName}>
              <span>{y}:</span> <span>{yearTotal.toLocaleString()}</span>
            </div>
            <div className={'popover-content2'}>
              {monthsRows}
            </div>
          </div>
        ))
      })

      return (
        <div className={'popover-wrapper'}>
          {total}
          <div className={'popover-content'}>
            {yearsRows}
          </div>
        </div>
      )
    }

    const ActionBtn = ({ record }) => (
      <ButtonWithConfirm
        onConfirm={() => usersActions.userAction(record.id, record.isActive ? 'block' : 'unblock')}
        button={<Button>{record.isActive ? <IconLockOpen/> : <IconLock/>}</Button>}
      />
    )

    const ResetPasswordBtn = ({ record }) => (
      <ButtonWithConfirm
        onConfirm={() => usersActions.resetFranchiseePassword(record.id)}
        button={<Button><IconResetPassword/></Button>}
      />
    )

    const Switch2FA = ({ record }) => (
      <Switch
        checked={record.secFA}
        onChange={() => usersActions.userSwitch2FA(record.id, !record.secFA)}
      />
    )

    const TransactionsListBtn = ({ record }) => (
      <Button onClick={this.handleTableBtnClick.bind(this, 'transactions', record)} label="transactions">
        <CreditCardIcon/>
      </Button>
    )

    const CashiersListBtn = ({ record }) => (
      <Button onClick={this.handleTableBtnClick.bind(this, 'cashiers', record)} label="cashiers">
        <CashiersIcon/>
      </Button>
    )

    const EditBtn = () => (
      <Button onClick={() => usersActions.setModalState('editFranchisee', true)}>
        <EditIcon/>
      </Button>
    )

    const BalanceField = ({ record, source }) => {
      const value = record[source] && record[source][record.fiat] !== undefined
        ? record[source][record.fiat] : ''
      return <div dangerouslySetInnerHTML={{ __html: value }}/>
    }

    const HtmlField = ({ record, source }) => {
      return <div dangerouslySetInnerHTML={{ __html: record[source] || '' }}/>
    }

    const UsersFilter = ({ ...props }) => <Filter {...props}><BooleanInput source="active" alwaysOn/></Filter>

    const CustomDrawer = ({ isOpen, type, currentUserId, label }) => {
      const { getCashiersList, getTransactionsList } = cashiersActions
      const loadFunc = type === 'cashiers' ? getCashiersList : getTransactionsList

      return (
        <Drawer
          variant="temporary"
          open={isOpen}
          anchor="right"
          onClose={this.handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <DrawerList
            onCancel={this.handleDrawerClose}
            openTransactionsAddModal={this.openTransactionsAddModal}
            dataList={this.props[type]}
            tableTotalItems={tableTotalItems}
            type={type}
            loadFunc={loadFunc}
            franchiseeId={currentUserId}
            cashiersActions={cashiersActions}
            cashier4edit={this.props.cashier4edit}
            label={label}
          />
        </Drawer>
      )
    }

    const UsersCustomActions = ({
      bulkActions,
      basePath,
      displayedFilters,
      filters,
      filterValues,
      onUnselectItems,
      resource,
      selectedIds,
      showFilter,
    }) => (
      <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <CreateButton basePath={basePath}/>
      </CardActions>
    )

    const cashierDrawerIsOpen = drawerType === 'cashiers' && drawerOpen
    const txDrawerIsOpen = drawerType === 'transactions' && drawerOpen
    const postRowClick = (id, basePath, record) => {
      this.setState({
        currentUser: record,
      })
    }

    // Which cols we have to show
    const removeCols = window.usersListRemoveCols || []
    const showCol = col => !removeCols.includes(col)

    return (
      <div>
        <List
          title="Franchisee"
          filters={<UsersFilter/>}
          filterDefaultValues={{ active: true }}
          {...propsRest}
          perPage={USERS_PAGINATION_OPTS[0]}
          pagination={<CustomPagination/>}
          actions={<UsersCustomActions/>}
          bulkActionButtons={false}
          sort={{ field: null, order: null }}
        >
          <Datagrid rowClick={postRowClick} className={'users-list'}>
            {showCol('name') ? <UserNameField label="User name" source="name"/> : null}
            {showCol('nickName') ? <TextField source="nickName" label="NickName"/> : null}
            {showCol('turnover') ? <FieldWithMonthsDetailing source="turnover" label="Turnover"/> : null}
            {showCol('profit') ? <FieldWithMonthsDetailing source="profit" label="ProfitF"/> : null}
            {showCol('ourProfit') ? <FieldWithMonthsDetailing source="ourProfit" label="ProfitQ"/> : null}
            {showCol('fiat') ? <TextField source="fiat" label="Fiat"/> : null}
            {showCol('virtualMoney') ? <HtmlField source="virtualMoney"/> : null}
            {showCol('balanceTest') ? <BalanceField source="balanceTest" label="Balance Test"/> : null}
            {showCol('balanceTransfers') ? <BalanceField source="balanceTransfers" label="Balance Transfers"/> : null}
            {showCol('balance') ? <BalanceField source="balance" label="Balance"/> : null}
            <ActionBtn/>
            <ResetPasswordBtn label="Reset passwd"/>
            <Switch2FA label="2FA"/>
            <TransactionsListBtn/>
            <CashiersListBtn/>
            <EditBtn/>
          </Datagrid>
        </List>

        <CustomDrawer isOpen={cashierDrawerIsOpen} type="cashiers"
                      label={currentUserName} currentUserId={currentUserId}
        />
        <CustomDrawer isOpen={txDrawerIsOpen} type="transactions"
                      label={currentUserName} currentUserId={currentUserId}
        />

        <BalanceUpdateModal
          cashiersActions={cashiersActions}
          id={currentUserId}
          isOpen={isOpenEditBalance}
          currentUser={this.state.currentUser}
          updateModalState={usersActions.setModalState}
        />
        <UserUpdateModal
          updateUser={usersActions.updateUser}
          isOpen={isOpenEditFranchisee}
          updateModalState={usersActions.setModalState}
          record={this.state.currentUser}
        />
        <UserShowModal
          isOpen={isOpenShowFranchisee}
          updateModalState={usersActions.setModalState}
          record={this.state.currentUser}
        />
      </div>
    )
  }
}

function mapDispatchToProps (dispatch) {
  return {
    usersActions: bindActionCreators(usersActions, dispatch),
    cashiersActions: bindActionCreators(cashiersActions, dispatch),
  }
}

function mapStateToProps (state) {
  return {
    users: state.usersState,
    admin: state.admin,
    cashiers: state.cashiersState.cashiers,
    cashier4edit: state.cashiersState.cashier4edit,
    transactions: state.cashiersState.transactions,
    tableTotalItems: state.cashiersState.tableTotalItems,
    tablesSortParams: state.cashiersState.tablesSortParams,
    drawerType: state.cashiersState.drawerType,
    drawerOpen: state.cashiersState.drawerOpen,
    modalType: state.usersState.modalType,
    modalOpen: state.usersState.modalOpen,
    currentUserId: state.usersState.currentUserId,
    currentUserName: state.usersState.currentUserName,
    currentUserFiat: state.usersState.currentUserFiat,
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(UsersList)
