import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { translate } from 'ra-core'
import BlockButton from './BlockButton'
import SubmitButton from './SubmitButton'
import NotificationLabel from './NotificationLabel'
import { CardActions } from 'react-admin'

class CustomActionButton extends Component {
  render () {
    const {
      serverBlocked, bulkActions,
      basePath,
      displayedFilters,
      filters,
      filterValues,
      onUnselectItems,
      resource,
      selectedIds,
      showFilter
    } = this.props

    return (
      <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
        <NotificationLabel isShow={serverBlocked}/>
        <BlockButton isBlocked={serverBlocked} onClick={this.props.onBlockToggle}/>
        <SubmitButton isDisabled={!serverBlocked} onClick={this.props.onSubmit}/>
      </CardActions>
    )
  }
}

CustomActionButton.propTypes = {
  translate: PropTypes.func,
  onBlockToggle: PropTypes.func,
  onSubmit: PropTypes.func,
  serverBlocked: PropTypes.bool
}

export default compose(
  translate,
)(CustomActionButton)

