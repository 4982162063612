import React from 'react'
import BaseModule from '../BaseModule'
import { Button } from 'react-admin'
import { UPDATE_ACTIONS } from '../Dashboard1'

export default class ModuleActions extends BaseModule {
  updateAction (action, value) {
    const data = {
      actions: `{"${action}":${value}}`
    }
    this.fetchData(UPDATE_ACTIONS, data)
      .then(data => {
        this.onDataLoad(data, true)
      })
  }

  renderBody (data) {
    return Object.entries(data).map(([action, value]) => {
      return (
        <Button
          key={action}
          label={action}
          className={'big-btn ' + (value ? 'big-btn_green' : 'big-btn_red')}
          style={{ marginBottom: '0.3rem' }}
          onClick={() => this.updateAction(action, value ? 0 : 1)}
        />
      )
    })
  }
}
