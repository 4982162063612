import React, { Component } from 'react'
import restApi from '../../../api/restApi'
import Select from 'react-select'
import { selectStyles } from './select-styles'

const Highcharts = window.Highcharts

// This is for all plots, change Date axis to local timezone
Highcharts.setOptions({
  global: {
    useUTC: false
  }
})

// routes
export const GET_CHART_LIVE = 'GET_CHART_LIVE'

const PARSE_ERROR_RELOAD_INTERVAL = 20
const DEFAULT_INTERVAL = 30

const RANGE_BUTTONS = [
  { type: 'day', count: 1, text: '24H' },
  { type: 'day', count: 7, text: '7d' },
  { type: 'month', count: 1, text: '1m' },
  { type: 'month', count: 3, text: '3m' },
  { type: 'month', count: 6, text: '6m' },
  { type: 'ytd', text: 'YTD' },
  { type: 'year', count: 1, text: '1y' },
  { type: 'all', text: 'All' }
]

/**
 * This Dashboard's lifecycle is very similar to BaseModule. Some code just copy-pasted with some changes
 * (almost copy of Dashboard 5)
 */
export default class Dashboard6_Charts extends Component {

  title = 'Charts'

  route = GET_CHART_LIVE

  state = {
    asset: null,

    moduleData: null,
    isDataError: false
  }

  fetchTimeoutId = null

  params = {
    min: null,
    max: null,
    btn: null
  }

  componentDidMount () {
    this.fetchData()
      .then(data => this.onDataLoad(data))
  }

  componentWillUnmount () {
    if (this.fetchTimeoutId) {
      clearTimeout(this.fetchTimeoutId)
    }
  }

  onDataLoad (data, once) {
    // reload data after interval
    if (!once) {
      const interval = data
        ? (data.interval ? data.interval : DEFAULT_INTERVAL)
        : PARSE_ERROR_RELOAD_INTERVAL

      this.fetchDataWithTimeout(interval)
        .then(data => this.onDataLoad(data))
    }

    this.setState({
      moduleData: data,
      isDataError: data === null
    })
  }

  // Fetch data
  // Return data or null in any case
  fetchData (asset) {
    return new Promise(resolve => {
      if (!asset && this.state.asset) {
        asset = this.state.asset
      }
      restApi(this.route, '', asset ? { asset } : {})
        .then(({ data }) => resolve(data))
        .catch(() => resolve(null))
    })
  }

  fetchDataWithTimeout (timeoutSec) {
    return new Promise(resolve => {
      this.fetchTimeoutId = setTimeout(() => {
        this.fetchData().then(resolve)
      }, timeoutSec * 1000)
    })
  }

  render () {
    const { moduleData, isDataError } = this.state
    let title = moduleData && moduleData.name ? moduleData.name : this.title
    if (isDataError) {
      title += ' [DATA PARSE ERROR]'
    }

    let body = null
    try {
      const data = moduleData ? moduleData.data : null
      body = !this.route || data ? this.renderBody(data) : null
    } catch (e) {
      title += ' [WRONG DATA]'
    }

    setTimeout(() => {
      const pageTitle = document.getElementById('react-admin-title')
      if (pageTitle) {
        pageTitle.innerText = title
      }
    })

    return (
      <div className={'dashboard dashboard-charts'}>
        {body}
      </div>
    )
  }

  /**************************** Render *******************************/

  updateHandler = () => {
    this.fetchData()
      .then(data => this.onDataLoad(data))
  }

  renderBody () {
    const { moduleData, asset } = this.state

    const data = Object.values(moduleData.data)
      .map(({ timeStamp, value }) => ({
        timeMs: +(new Date(timeStamp)),
        value: Math.abs(value) > 1000 ? Math.round(value) : value
      }))

    const seriesData = generateSeriesData(data)

    const rangeBtn = this.params.btn
    const rangeMin = this.params.min
    const rangeMax = this.params.max

    setTimeout(() => {
      // Create chart
      this.chart = Highcharts.stockChart('rates-chart', {
        rangeSelector: {
          buttons: RANGE_BUTTONS,
          selected: rangeBtn ? +rangeBtn : null
        },
        yAxis: {
          plotLines: [{
            value: 0,
            width: 2,
            color: 'silver'
          }]
        },
        xAxis: {
          ordinal: false,
          min: rangeMin ? +rangeMin : null,
          max: rangeMax ? +rangeMax : null,
          events: {
            setExtremes: e => {
              let btn = null
              if (e.rangeSelectorButton) {
                const btnIdx = RANGE_BUTTONS.findIndex(x => x.text === e.rangeSelectorButton.text)
                btn = btnIdx !== -1 ? btnIdx : null
              }
              this.params.min = e.min
              this.params.max = e.max
              this.params.btn = btn
              // this.saveParams()
            }
          }
        },
        legend: { enabled: true },
        tooltip: {
          pointFormat: '<span style="color:{series.color};">{series.name}</span>: <b>{point.y}</b><br/>',
          split: true
        },
        responsive: {
          rules: [{
            condition: { maxWidth: 1500 },
            chartOptions: {
              chart: { height: 500 },
            }
          }]
        },
        series: Object.values(seriesData)
      })
    })

    const assets = moduleData.additionalData.assets
    const assetOpts = assets.map(x => ({ value: x, label: x }))
    const selectedOpt = assetOpts.find(x => x.value === asset)

    const handleAssetSelect = (asset) => {
      this.fetchData(asset)
        .then(data => {
          this.setState({ asset })
          this.onDataLoad(data)
        })
    }

    return (
      <div>
        <div className={'controls'}>
          <span>Asset:</span>
          <Select
            options={assetOpts}
            value={selectedOpt}
            onChange={e => handleAssetSelect(e.value)}
            styles={selectStyles}
          />
        </div>

        <div id={'rates-chart'}/>
      </div>
    )
  }
}

/******************************** chart functions ******************************************/

function generateSeriesData (data) {
  const series = {
    value: {
      name: 'value',
      data: []
    }
  }


  window.dd = data

  data.forEach(row => {
    series.value.data.push([row.timeMs, +row.value])
  })

  return series
}
